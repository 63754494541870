import * as monaco from 'monaco-editor'
import Editor from '@monaco-editor/react'
import { useState } from 'react';
import  { BsCopy } from "react-icons/bs";
import { PiDownloadSimpleBold } from "react-icons/pi";
import { Tooltip } from '@nextui-org/react';
import styles from './quantdle-code-editor.module.css';
import toast from 'react-hot-toast';

export const QuantdleCodeEditor = (props: {code: string, downloadName?: string, title?: string, language?: string, height?: number | string, width?: number | string}) => {

    const [themeReady, setThemeReady] = useState<boolean>(false)


    const handleEditorDidMount = (editor: any, monaco: any) => {
        setThemeReady(false)
        monaco.editor.defineTheme('quantdle-theme', {
            base: 'vs-dark',
            inherit: true,
            rules: [
                {
                    token: "identifier",
                    foreground: "#edc880"
                },
                {
                    token: "variable",
                    foreground: "#17C964"
                },
                {
                    token: "keyword",
                    foreground: "#B583E7"
                },
                {
                    token: "comment",
                    foreground: "#7a7a7a"
                },
                {
                    token: "string",
                    foreground: "#78F2AD"
                }
            ],
            colors: {
                'editor.background': "#090716"
            }
        });
        monaco.editor.setTheme('quantdle-theme')
        setThemeReady(true)
    }

    const handleOnCopyClick = () => {
        navigator.clipboard.writeText(props.code)
        toast.success("Code Copied!")
    }


    const handleOnDownload = () => {
        const element = document.createElement("a");
        const file = new Blob([props.code], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = props.downloadName ?? `python_code_snippet.py`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    return (
    <div style={{display: 'flex', zIndex: 10000, flexDirection: 'column', width: props.width ?? '100%', borderRadius: 12, overflow: 'hidden'}}>
        {
        props.title ?
        <div style={{display: 'flex', justifyContent: 'space-between', padding: 5, height: 37, background: 'var(--nextui-colors-backgroundAlpha)'}}>
            <div style={{marginLeft: 5}}>{props.title}</div>
            <div style={{display: 'flex'}}>
                <div style={{cursor: 'pointer', width: 30}} onClick={handleOnDownload}>
                    <Tooltip trigger='hover' content={"Download"} placement='top'>
                        <PiDownloadSimpleBold className={styles['copy-icon']}/>
                    </Tooltip>
                </div>
                <div style={{cursor: 'pointer', width: 22}} onClick={handleOnCopyClick}>
                    <Tooltip trigger='hover' content={"Copy"} placement='top'>
                        <BsCopy className={styles['copy-icon']}/>
                    </Tooltip>
                </div>
            </div>
        </div>
        : 
        <div style={{position: 'relative', zIndex: 1}}>
            <div style={{position: 'absolute', top: 2, right: 20, cursor: 'pointer'}} onClick={handleOnCopyClick}>
                <Tooltip trigger='hover' content={"Copy"} placement='top'>
                        <BsCopy className={styles['copy-icon']}/>
                </Tooltip>
            </div>
        </div>
        }
        <Editor  
                options={{
                    minimap: {
                        enabled: false
                    },
                    readOnly: true,
                    renderLineHighlight: 'none',
                    
                }}
                theme={themeReady ? 'quantdle-theme' : 'vs-dark'} 
                language={props.language ?? 'python'} 
                value={props.code} 
                width={props.width ?? '100%'}
                height={props.height ?? 500} 
                onMount={handleEditorDidMount}
                />
    </div>
    )

}