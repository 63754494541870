import { ReactNode } from "react"
import ParticleBackground from "../components/particles/particles-background"
import { HeaderRedesignComponent } from "../components/header/header-redesign-component"
import { SidenavComponent } from "../components/sidenav/sidenav.component"

type LayoutProps = {
    children: ReactNode
}

export const AdminLayout = ({children}: LayoutProps) => {
    return (
        <div>
            
            <HeaderRedesignComponent />
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <SidenavComponent />
                <div style={{width: '100%', maxHeight: 'calc(100vh)', height: 'calc(100vh - var(--nextui--navbarHeight))', overflowY: 'scroll', overflowX: 'hidden', zIndex:"0"}}>
                    {children}
                </div>
            </div>
        </div>
    )
}