/* tslint:disable */
/* eslint-disable */
/**
 * API Gateway para Webhooks de Stripe
 * API Gateway para recibir eventos de webhook de Stripe
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreateCheckoutSessionResponse } from '../models';
import { CreateSubscriptionBody } from '../models';
import { CreateSubsriptionResponse } from '../models';
import { GenericResponse } from '../models';
import { InlineResponse200 } from '../models';
import { ListProductsResponse } from '../models';
/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This enpoint will call stripe to redirect client to the checkout page to perform the payment
         * @summary Create a checkout session for subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPortalSessionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/create_portal_session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This enpoint will call stripe to redirect client to the checkout page to perform the payment
         * @summary Create a checkout session for subscription
         * @param {CreateSubscriptionBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriptionPost: async (body?: CreateSubscriptionBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/create_subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This enpoint will call stripe to redirect client to the checkout page to perform the payment
         * @summary Create a checkout session for subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethodsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/get_payment_methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint list all the products registered
         * @summary List avaiabel products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * This enpoint will call stripe to redirect client to the checkout page to perform the payment
         * @summary Create a checkout session for subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPortalSessionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<InlineResponse200>>> {
            const localVarAxiosArgs = await PaymentsApiAxiosParamCreator(configuration).createPortalSessionGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This enpoint will call stripe to redirect client to the checkout page to perform the payment
         * @summary Create a checkout session for subscription
         * @param {CreateSubscriptionBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscriptionPost(body?: CreateSubscriptionBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CreateSubsriptionResponse>>> {
            const localVarAxiosArgs = await PaymentsApiAxiosParamCreator(configuration).createSubscriptionPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This enpoint will call stripe to redirect client to the checkout page to perform the payment
         * @summary Create a checkout session for subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentMethodsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CreateCheckoutSessionResponse>>> {
            const localVarAxiosArgs = await PaymentsApiAxiosParamCreator(configuration).getPaymentMethodsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This endpoint list all the products registered
         * @summary List avaiabel products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<ListProductsResponse>>>> {
            const localVarAxiosArgs = await PaymentsApiAxiosParamCreator(configuration).productsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * This enpoint will call stripe to redirect client to the checkout page to perform the payment
         * @summary Create a checkout session for subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPortalSessionGet(options?: AxiosRequestConfig): Promise<AxiosResponse<InlineResponse200>> {
            return PaymentsApiFp(configuration).createPortalSessionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * This enpoint will call stripe to redirect client to the checkout page to perform the payment
         * @summary Create a checkout session for subscription
         * @param {CreateSubscriptionBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscriptionPost(body?: CreateSubscriptionBody, options?: AxiosRequestConfig): Promise<AxiosResponse<CreateSubsriptionResponse>> {
            return PaymentsApiFp(configuration).createSubscriptionPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * This enpoint will call stripe to redirect client to the checkout page to perform the payment
         * @summary Create a checkout session for subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentMethodsGet(options?: AxiosRequestConfig): Promise<AxiosResponse<CreateCheckoutSessionResponse>> {
            return PaymentsApiFp(configuration).getPaymentMethodsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint list all the products registered
         * @summary List avaiabel products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsGet(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ListProductsResponse>>> {
            return PaymentsApiFp(configuration).productsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * This enpoint will call stripe to redirect client to the checkout page to perform the payment
     * @summary Create a checkout session for subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public async createPortalSessionGet(options?: AxiosRequestConfig) : Promise<AxiosResponse<InlineResponse200>> {
        return PaymentsApiFp(this.configuration).createPortalSessionGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This enpoint will call stripe to redirect client to the checkout page to perform the payment
     * @summary Create a checkout session for subscription
     * @param {CreateSubscriptionBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public async createSubscriptionPost(body?: CreateSubscriptionBody, options?: AxiosRequestConfig) : Promise<AxiosResponse<CreateSubsriptionResponse>> {
        return PaymentsApiFp(this.configuration).createSubscriptionPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This enpoint will call stripe to redirect client to the checkout page to perform the payment
     * @summary Create a checkout session for subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public async getPaymentMethodsGet(options?: AxiosRequestConfig) : Promise<AxiosResponse<CreateCheckoutSessionResponse>> {
        return PaymentsApiFp(this.configuration).getPaymentMethodsGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint list all the products registered
     * @summary List avaiabel products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public async productsGet(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<ListProductsResponse>>> {
        return PaymentsApiFp(this.configuration).productsGet(options).then((request) => request(this.axios, this.basePath));
    }
}
