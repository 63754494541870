import  { useEffect, useState } from 'react';

import {subDays, format } from 'date-fns';
import { DateRange, DayPicker } from 'react-day-picker';
import { enUS } from 'date-fns/locale';
import { Text } from '@nextui-org/react';

const css = `
  .my-selected:not([disabled]) {
    font-weight: bold;
    border: 2px solid var(--nextui-colors-primary);
    background-color: var(--nextui-colors-primary);
  }
  .my-selected:hover:not([disabled]) {
    border: 2px solid var(--nextui-colors-primary);
    color: var(--nextui-colors-background);
    background: var(--nextui-colors-background);
  }

  .my-today {
    font-weight: bold;
    font-size: 140%;
    color: var(--nextui-colors-primary);
  }

  .rdp-months{
    justify-content: center
  }

`;

export interface DataRangePickerProps {
  fromDate?: Date,
  toDate?: Date,
  selectedRange?: DateRange,
  onRangeChange?: (range: DateRange) => void
}

export const DateRangePicker = (props: DataRangePickerProps) => {

  const currentDate = props.toDate ?? new Date();
  const defaultSelected: DateRange = {
    from: subDays(currentDate, 4),
    to: currentDate
  };
  
  useEffect(() => {

    if(props.selectedRange){
      setRange(props.selectedRange)
    }else{
      setRange(defaultSelected)
    }

  }, [props.selectedRange])

  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);


  const handleOnSelect = (dateRange: DateRange | undefined) => {

    setRange(dateRange)

    if(props.onRangeChange && dateRange){
      props.onRangeChange(dateRange)
    }

  }

  let footer = <Text>Select a Date</Text>;
  if (range?.from) {
    if (!range.to) {
      footer = <Text>{format(range.from, 'P', {locale: enUS})}</Text>;
    } else if (range.to) {
      footer = (
        <Text b>
          From {format(range.from, 'P', {locale: enUS})} to {format(range.to, 'P', {locale: enUS})}
        </Text>
      );
    }
  }
  

  return (
    <>
      <style>{css}</style>
      <DayPicker
        mode="range"
        locale={enUS}
        defaultMonth={currentDate}
        selected={range}
        footer={footer}
        fromDate={props.fromDate ?? new Date(2017,9,2)}
        toDate={currentDate}
        captionLayout="dropdown-buttons"
        onSelect={handleOnSelect}
        modifiersClassNames={{
          selected: 'my-selected',
          today: 'my-today',
        }}
        modifiersStyles={{
          disabled: { fontSize: '75%' }
        }}
        styles={{

          dropdown: {
            accentColor: 'var(--nextui-colors-primary)',
            backgroundColor: 'var(--nextui--navbarBlurBackgroundColor)',
            borderRadius: 24
          }
        }}
      />
    </>

  );
}
