
// a component to montitor the status of a transfer in root of app

import { useEffect } from "react"
import { DownloadsApi } from "../../shared/download-api/api"
import { useAppDispatch, useAppSelector } from "../../shared/hooks/redux.hook"
import { addJobStatus } from "../../shared/redux/job-status-slice"
import { JobStatusEnum, TaskStatusEnum } from "../../shared/models/quantdle-job-status-update"
import { useQuantdleApi } from "../../shared/hooks/quantdle-api.hook"
import { useAuthenticator } from "@aws-amplify/ui-react"

 

export const TransferStatusComponent = () => {
    
    const dispatch = useAppDispatch()
    const { authStatus } = useAuthenticator()
    const { downloadsApi } = useQuantdleApi()

    // retrieve jobs also from the rest api
    useEffect(() => {

        if(authStatus !== 'authenticated' || !downloadsApi) return

        downloadsApi.listGet().then((jobs) => {
            // add the jobs to the redux store
            jobs.data?.forEach((job) => {
                    dispatch(addJobStatus({
                        jobId: job.jobId,
                        taskStatusUpdates: job.taskStatuses.map((status) => {
                            return {
                                symbol: status.symbol,
                                status: TaskStatusEnum[status.status] ?? TaskStatusEnum.COMPLETED,
                            } 
                        }),
                        status: JobStatusEnum[job.status] ?? JobStatusEnum.COMPLETED,
                        fileSize: job.fileSize,
                        dataFrom: job.dataFrom?.toString(),
                        dataTo: job.dataUntil?.toString(),
                        createdOn: job.createdOn?.toString(),
                    }))
                })
        })

    }, [downloadsApi])

    
    return <></>
}
