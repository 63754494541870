import { QuantdleNotification } from "../models/quantdle-notification";
import { WebsocketApiMessage } from "../websocket-api/api";


export function fromServerFormatToQuantdleNotification(message: WebsocketApiMessage): QuantdleNotification {
    return {
        notificationId: message.notification_id,
        isRead: message.is_read,
        message: message.message,
        title: message.title,
        topic: message.topic,
        datetime: message.event_datetime,
    }
}