import { configureStore, combineReducers } from '@reduxjs/toolkit'
import thunk from "redux-thunk";
import { notificationSlice } from './notification-slice';
import { jobStatusSlice } from './job-status-slice';
import { usageSlice } from './usage-slice'

const rootReducer = combineReducers({
  notification: notificationSlice.reducer,
  jobStatus: jobStatusSlice.reducer,
  usage: usageSlice.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(thunk) // I don't really know why but this is the way you load the middlewares -> https://github.com/reduxjs/redux-toolkit/issues/587#issuecomment-824927971
})



// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
