import { useMemo } from "react";
import { TaskStatusEnum, TaskStatusUpdate } from "../../shared/models/quantdle-job-status-update";
import { Badge, Loading, Progress, Text } from "@nextui-org/react";
import styles from './job-status.module.css'

const taskSteps = Object.keys(TaskStatusEnum).length - 2; // -3 because we don't want to count the failed and pending steps
//* stepMap value of each step the value is 1, if is is cleaninsing the value is 2, and if it is compresing the cvalue is 3, this must be updated if TaskStatus enum changes
const stepMap = {
    [TaskStatusEnum.PENDING]: 0,
    [TaskStatusEnum.RETRIEVING]: 1,
    [TaskStatusEnum.PROCESSING]: 2,
    [TaskStatusEnum.COMPRESSING]: 3,
    [TaskStatusEnum.COMPLETED]: 4,
    [TaskStatusEnum.FAILED]: 4
}

export const JobStatusComponent = (props: {status: TaskStatusUpdate[]}) => {


    // this will be a component that has a progress bar and a status text
    const totalSteps = useMemo(() => props.status.length * taskSteps, [props.status.length])
    const currentStep = useMemo(() => {
        let step = 0;
        props.status.forEach((status) => {
            step += stepMap[status.status] 
        })
        return step;
    }, [props.status])
    
    // compute the percentage
    const percentage = useMemo(() => {
        return (currentStep / totalSteps) * 100;
    }, [currentStep, totalSteps])

    // stract the text from the status that is not in pending state
    const statusText = props.status.filter((status) => status.status !== TaskStatusEnum.COMPLETED).map((status) => `${status.status.toLowerCase()} ${status.symbol.toUpperCase()}`)
    const isJobPending = props.status.every((status) => status.status === TaskStatusEnum.PENDING)

    // check if all task are completed
    if(props.status.length > 0 && props.status.every((status) => status.status === TaskStatusEnum.COMPLETED)) {
        // return a badge with ready status
        return <Badge variant='flat' color={'success'}>READY</Badge>
    }

    const capitalize = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifySelf: 'center', maxWidth: 200}}>
            <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', marginBottom: 5}}>
                <Loading size='sm' type="spinner" style={{marginRight: 5}}/>
                
                { !isJobPending ?
                    <>
                        <Badge  variant={'flat'} color={'secondary'} size={'sm'} disableOutline>
                                {statusText.length > 0 ? capitalize(statusText[0]) : ''}
                        </Badge>
                        <Badge variant={'flat'} css={{
                            "--nextui--badgeBackgroundColor": "#0a2440 !important",
                            "--nextui--badgeTextColor": "#06b7db !important"
                        }} size={'sm'} disableOutline style={{marginLeft: 5}}>RUNNING</Badge>
                    </>
                    :
                    <Badge variant={'flat'} color={'warning'} size={'sm'} disableOutline style={{marginLeft: 5}}>PENDING</Badge>
                }
            </div>
            {!isJobPending  && <Progress size='xs' value={percentage} color={'secondary'}/>}
        </div>
    )

}