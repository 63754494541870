import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import particlesConfig from "./config/particles-config";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useCallback } from "react";


export default function ParticleBackground() {
  
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  
  const particlesInit = useCallback(async (engine: any) => {
      await loadFull(engine);
    }, []);


  
  if(authStatus === 'authenticated'){
    return <></>
  }


  return (
    <Particles
      options={particlesConfig}
      id = "tsparticles"
      init = { particlesInit }
    />
  );
}


