
export enum TaskStatusEnum {
    PENDING = 'PENDING',
    RETRIEVING = 'RETRIEVING',
    PROCESSING = 'PROCESSING',
    COMPRESSING = 'COMPRESSING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED'
}

export enum JobStatusEnum {
    PENDING = 'PENDING',
    RUNNING = 'RUNNING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED'
}

export interface TaskStatusUpdate {
    symbol: string,
    status: TaskStatusEnum,
}

export interface JobStatusUpdate {

    jobId: string,
    status: JobStatusEnum,
    fileSize?: number,
    dataFrom?: string,
    dataTo?: string,
    createdOn?: string,
    taskStatusUpdates: TaskStatusUpdate[]

}