import { fromServerFormatToQuantdleNotification } from "../mappers/quantdle-notifications.mappers"
import { QuantdleNotification } from "../models/quantdle-notification"


export interface WebsocketApiConfig {
    basePath: string
    accessToken: string
}

export interface WebsocketApiMessage {
    action: string
    [key: string]: any
}


export class WebsocketApi {

    private client: WebSocket = undefined
    private url: string
    private onConnectCallback: () => void;
    private onMessageCallback: (message: QuantdleNotification) => void;

    constructor(private config: WebsocketApiConfig) {
        this.config = config
        this.url = this.config.basePath + '?Auth=' + this.config.accessToken
    }


    public connect() {
        this.client = new WebSocket(this.url)
        this.client.onopen = () => {
            this.onConnectCallback()
        }
        this.client.onmessage = (message) => {
            const notification = fromServerFormatToQuantdleNotification(JSON.parse(message.data))
            this.onMessageCallback(notification)
        }
    }

    private checkConnection() {
        if (!this.client) {
            throw new Error('Websocket client is not connected, call `connect` first')
        }
    }

    public send(message: WebsocketApiMessage) {

        // check if the client is connected
        this.checkConnection()
        this.client.send(JSON.stringify(message))
    }

    public markNotificationAsRead(notificationId: string) {

        this.checkConnection()
        this.send({
            action: 'setNotificationRead',
            notification_id: notificationId
        })
    }

    public deleteNotification(notificationId: string) {
        this.checkConnection()
        this.send({
            action: 'deleteNotification',
            notification_id: notificationId
        })
    }

    public onConnect(callback: () => void) {

        // check if the client is connected
        this.onConnectCallback = callback
    }


    onMessage(callback: (message: QuantdleNotification) => void) {

        this.onMessageCallback = callback
    }









}