// 1. Import `createTheme`
import { createTheme } from "@nextui-org/react"

// 2. Call `createTheme` and pass your custom values
export const darwinex = createTheme({
  type: "dark", // it could be "light" or "dark"
  theme: {
    colors: {
      // brand colors
      background: '#15151B',
      backgroundColor: '#eb93461c',
      primaryLight: '#eb934657',
      primaryLightHover: '#eb9346db',
      primaryLightActive: '#eba05e',
      primaryLightContrast: '#170a1c',
      primary: '#eb9346',
      primaryBorder: '#eb9346',
      primaryBorderHover: '#7ea8cc',
      primarySolidHover: '#7ea8cc',
      primarySolidContrast: '$white',
      primaryShadow: '#170a1c',
      backgroundAlpha: '#202028',
      hoverColor: '#eb93461c',
      border: '#202028',
      gradient: 'linear-gradient(135deg,#0de0cf 0%,#00418e 100%)',
      link: '#eb9346',
      sideNavHover: '#eb93461c'
    },
    radii: {
      'md': '7px'
    },
    space: {},
    fonts: {}
  }
})


export const quantdleTheme = {
  type: "dark", // it could be "light" or "dark"
  theme: {
    colors: {
      // brand colors
      background: '#090716',
      backgroundColor: '#090716',
      primaryLight: '#9800fe59',
      primaryLightHover: '#bf62fd59',
      primaryLightActive: '#110F24',
      primaryLightContrast: '#c6aecf',
      primary: '#9800fe',
      primaryBorder: '#eb9346',
      primaryBorderHover: '#0e9bf524',
      primarySolidHover: '#9800fe59',
      primarySolidContrast: '$white',
      primaryShadow: '#170a1c',
      backgroundAlpha: '#26243b', //'#202028',
      hoverColor: '#9800fe59',
      border: '#202028',
      gradient: 'linear-gradient(135deg,#0de0cf 0%,#00418e 100%)',
      link: '#eb9346',
      sideNavHover: '#0e9bf524',
      backgroundContrast: '#110F24',
      inputColor: '#110F24',
      accents1: '#24203b',
      neutral: '#0f0e1a', // pop up background
      neutralLight: '#0f0e1a', // pop up background
      accents0: '#363156', // for table headers and inputs
      // accents7: 'white' // for table headers text
    },
    radii: {
      'md': '12px'
    },
    space: {},
    fonts: {
      sans: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto','Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
      mono: "Menlo, Monaco, 'Lucida Console', 'Liberation Mono','DejaVu Sans Mono', 'Bitstream Vera Sans Mono'"
    },
    
    
  }
}

export const quantdleLigthTheme = {
  type: "light", // it could be "light" or "dark"
  theme: {
    colors: {
      // brand colors
      background: '#fafafa',
      secondary: '#9800fe59',
      secondaryLightContrast: '#c066fc',
      // backgroundColor: '#090716',
      primaryLight: '#9800fe59',
      primaryLightHover: '#d391ff59',
      primaryLightActive: '#110F24',
      primaryLightContrast: '#782bc6',
      primary: '#c165ff',
      primaryBorder: '#eb9346',
      primaryBorderHover: '#0e9bf524',
      primarySolidHover: '#9800fe59',
      primarySolidContrast: '$white',
      primaryShadow: '#170a1c',
      backgroundAlpha: '#e3e3e3',
      text: '#7a7a7a',
      hoverColor: '#9800fe59',
      border: '#e7e7e7a8',
      gradient: 'linear-gradient(135deg,#0de0cf 0%,#00418e 100%)',
      link: '#eb9346',
      sideNavHover: '#0e9bf524',
      // backgroundContrast: '#110F24',
      inputColor: '#ffffff',
      accents1: '#ddd0e759',
      neutral: '#7a7a7a', // pop up background
      neutralLight: '#e7e7e7a8', // pop up background
      accents0: '#363156', // for table headers and inputs
      // accents7: 'white' // for table headers text
    },
    radii: {
      'md': '12px'
    },
    space: {},
    fonts: {
      sans: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto','Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
      mono: "Menlo, Monaco, 'Lucida Console', 'Liberation Mono','DejaVu Sans Mono', 'Bitstream Vera Sans Mono'"
    },
    
    
  }
}

export const quantdle = createTheme(quantdleTheme)
export const quantdleLigth = createTheme(quantdleLigthTheme)