import { Button, Modal, Text, Radio, Input, Badge, Link } from "@nextui-org/react";
import { DateRangePicker, DataRangePickerProps } from "../date-range-picker/date-range-picker.component";
import { useState } from "react";
import { DateRange } from "react-day-picker";
import styles from './download-modal.module.css'
import { WarningIcon } from "../icons/warning-icon.component";
import { format, subDays } from "date-fns";
import { enUS } from "date-fns/locale";
import { useAppSelector } from "../../shared/hooks/redux.hook";

export interface DownloadDateModalComponentProps {
    visible?: boolean,
    onClose?: () => void,
    onDownload?: (dateRange: DateRange, selectedFormat: string) => void,
    fromDate?: Date,
    toDate?: Date,
    instrumentNames?: string[]
}

export default function DownloadModalComponent(props: DownloadDateModalComponentProps & DataRangePickerProps){

    const usage = useAppSelector(state => state.usage)
    const isGreaterThanAllowed = usage.customDownloads > usage.maxCustomDownloads
    const [dateRange, setDateRange] = useState<DateRange>()
    const [warningClass, setWarningClass] = useState(styles["warning"] + ' ' + styles["hidden"])
    const [isNext, setIsNext] = useState(false)
    const [selectedRange, setSelectedRange] = useState<DateRange>( {
        from: props.toDate ? subDays(props.toDate, 6) : subDays(new Date(), 4),
        to: props.toDate ?? subDays(new Date(), 2)
    })
    const [selectedFormat, setSelectedFormat] = useState<string>("MT5")
    const [radioSelection , setRadioSelection] = useState<string>("custom")

    const from = props.fromDate
    const to = props.toDate


    const makeWarningDissapear = () => {
        // check that the warning message is visible, if it is, then make it dissapear by setting the styles['fade-out'] class and then set the styles['hidden'] class
        if(warningClass.includes(styles["fade-in"])){
            setWarningClass(styles["warning"] + ' ' + styles["fade-out"])
            setTimeout(() => {
                setWarningClass(styles["warning"] + ' ' + styles["hidden"])
            }, 500);
        }
    }

    function handleOnChange(value: string){

        const lastMonthDate = new Date(new Date().setMonth(to.getMonth() - 1))
        const lastYearDate = new Date(new Date().setFullYear(to.getFullYear() - 1))
        setRadioSelection(value)
        // set the range based on the value, if value is month, then set the range to last month, if year, then set the range to last year, if all, then set the range to all
        switch(value){
            case "month":
                setSelectedRange({
                    from: lastMonthDate > from ? lastMonthDate : from,
                    to: to
                })
                makeWarningDissapear()
                break;
            case "year":
                setSelectedRange({
                    from: lastYearDate > from ? lastYearDate : from,
                    to: to
                })
                makeWarningDissapear()
                break;
            case "all":
                setSelectedRange({
                    from,
                    to
                })
                setWarningClass(styles["warning"] + ' ' + styles["fade-in"])
                break;
            case "custom":
                setSelectedRange(undefined)
                makeWarningDissapear()
                break;
            default:
                break;

            }

    }

    function handleOnFormatSelectionChange(value: string){
        setSelectedFormat(value)
    }

    function handleOnDownload(){
        if(props.onDownload && selectedRange && selectedFormat){
            props.onDownload(selectedRange, selectedFormat)
        }
        props.onClose()
        setIsNext(false)
    }

    return (
            <Modal
                closeButton
                preventClose
                aria-labelledby="modal-title"
                open={props.visible}
                onClose={props.onClose}
            >
                <Modal.Header>
                    <Text h1 id="modal-title" size={18}>
                        {isNext ? "Summary" : "Data configurations"}
                    </Text>
                </Modal.Header>
                <Modal.Body style={{display: 'flex', justifyContent: 'center', alignItems: 'self-start'}}>
                    {
                    isNext ?
                        <>
                            <Text style={{userSelect: 'none'}}>Instruments</Text>
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                            {props.instrumentNames?.map(item => item.toUpperCase()).map(
                                        item => {
                                            return <Badge disableOutline variant='flat' style={{margin: 2}} color={'success'}>{item}</Badge>
                                        }
                                    )}
                            </div>
                            <Input 
                                label="Format"
                                css={{ $$inputColor: "var(--nextui-colors-inputColor)" }}
                                contentLeft={
                                    // selectedFormat.map(item => item.toUpperCase()).map(
                                    //     item => {
                                    //         return <Badge variant={'flat'}>{item}</Badge>
                                    //     }
                                    // )
                                    <Badge disableOutline variant={'flat'}>{selectedFormat.toUpperCase()}</Badge>
                                } 
                                readOnly
                            />
                            <Input 
                                label="Data range" 
                                css={{ $$inputColor: "var(--nextui-colors-inputColor)" }}
                                initialValue={`${format(from, 'P', {locale: enUS})} - ${format(to, 'P', {locale: enUS})}`}
                                readOnly
                            />

                        </>
                    :
                    <>
                        {/* <Radio.Group
                            size="md"
                            label="Select data format"
                            orientation="horizontal"
                            color="secondary"
                            defaultValue={selectedFormat}
                            onChange={handleOnFormatSelectionChange}
                            >
                            <Radio value="MT4">MT4</Radio>
                            <Radio value="MT5">MT5</Radio>
                            <Radio value="JSON">JSON</Radio>
                        </Radio.Group> */}
                        <Radio.Group 
                            label="Select date range"
                            orientation="horizontal" 
                            defaultValue={radioSelection}
                            size="sm" 
                            onChange={handleOnChange}>
                            <Radio value="all">All</Radio>
                            <Radio value="month">Last month</Radio>
                            <Radio value="year">Last year</Radio>
                            <Radio value="custom">Custom</Radio>
                        </Radio.Group>
                        <div style={{display: 'flex', justifyContent: 'center', width: "100%"}}>
                            <DateRangePicker fromDate={props.fromDate} toDate={props.toDate} selectedRange={selectedRange} onRangeChange={(setDateRange)}></DateRangePicker>
                        </div>
                        <div style={{display: 'flex', textAlign: 'justify', userSelect: 'none'}} className={warningClass}>
                            <div style={{display: 'flex', alignItems: 'center', marginRight: 25}}><WarningIcon size={32}/></div>
                            <Text color="currentColor"> Warning! Downloading all data may consume your monthly download bandwith.</Text>
                        </div>
                    </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button auto flat color="error" onPress={() => {
                            props.onClose() 
                            setIsNext(false)
                        }}
                    >
                        Close
                    </Button>
                    {
                        isNext ?
                        <>
                            <Button auto ghost onPress={() => setIsNext(false)}>
                                Back
                            </Button>
                            <Button auto disabled={isGreaterThanAllowed} onPress={() => handleOnDownload()}>
                                Download
                            </Button>
                        </>
                        :
                        <Button auto onPress={() => setIsNext(true)}>
                            Next
                        </Button>
                    
                    }
                    <div style={{display: 'flex', flexDirection: 'column',  justifyContent: 'center'}}>
                    { isGreaterThanAllowed && 
                        <div style={{display: 'flex', textAlign: 'justify', userSelect: 'none', marginTop: 15}} className={styles.warning}>
                            <div style={{display: 'flex', alignItems: 'center', marginRight: 25}}><WarningIcon size={32}/></div>
                            <Text color="currentColor"> You won't be able to download, as you have reached the max number of custom downloads</Text>
                        </div>
                    }
                    {
                        isGreaterThanAllowed &&
                        <Text><Link color={'secondary'} href="https://quantdle.com/pricing.html">Upgrade your plan</Link> to get more custom downloads!</Text>
                    }
                    </div>
                </Modal.Footer>
            </Modal>
            )

}

