import { Grid, Text, Dropdown, Modal, Button, Spacer } from "@nextui-org/react"
import { useState } from "react";
import DownloadDateModalComponent from "../download-date-modal/download-date-modal.component";
import { es } from "date-fns/locale";
import { format, subDays } from "date-fns";
import  styles  from './symbol.module.css';
import { Chip } from "./chip";
import { assetTypes } from "../../shared/constants/symbol.constants";

interface DropdownOption {
  key: string,
  name: string
}

export const SymbolCompoent = (props: {asset: string, error?: string, assetType: string, subAssetClass: string, highlight?: boolean, hide?: boolean, onAssetClick?: (asset: string) => void,  onAssetTypeClick?: (value: string, label: string) => void, onSubAssetTypeClick?: (value: string, label: string) => void}) => {


  // const status = useAppSelector((state) => state.assetDownload.assets.find(asset => asset.asset === props.asset))
  // const candleApi = useCandleApi()
  const [downloadModalVisible, setDownloadModalVisible] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [dataNotDownloadedModalVisible, setDataNotDownloadedModalVisible] = useState(false)


  const handleAssetClick = (e: React.MouseEvent<unknown, MouseEvent>) =>{
    if(props.onAssetClick){
      props.onAssetClick(props.asset)
    }
  }

  const handleSubAssetTypeClick = () => {
    if(props.onSubAssetTypeClick){
      props.onSubAssetTypeClick(props.assetType, props.subAssetClass)
    }
  }
  

  return(
    <div className={props.hide ? styles['scale-out-center'] : styles['scale-in-center']}>
      <Grid style={{minWidth: 275, maxWidth: 275, borderRadius: '7px', margin: 10, display:"flex", alignContent: "center", backgroundColor:"var(--nextui-colors-backgroundContrast)", justifyContent: 'space-between'}}  className={props.highlight ? styles['selected'] + ' ' + styles.asset : styles.asset}>
        <Grid xs={8} onClick={(e) => handleAssetClick(e)} style={{display: 'flex' , flexDirection: 'row', cursor: 'pointer'}} >
          <Text h4 style={{margin: 0, userSelect: "none"}}>{props.asset}</Text>
        </Grid>
        <Grid xs={4} style={{display: 'flex', alignItems: 'center'}}>
          <div style={{marginRight: 5}}>
            <Chip
              label={props.assetType == 'FOREX' ? 'FX' : props.assetType.substring(0,2)}
              value={props.assetType.toLowerCase()}
              color={assetTypes.find(assetType => assetType.value == props.assetType.toLowerCase())?.color ?? 'secondary'}
              onClick={props.onAssetTypeClick}
            />
          </div>
          <Chip
            label={props.subAssetClass.substring(0,2)}
            value={props.subAssetClass.toLowerCase()}
            color="success"
            onClick={(label, value) => handleSubAssetTypeClick()}
          />
        </Grid>
        <DownloadDateModalComponent visible={downloadModalVisible} onClose={() => setDownloadModalVisible(false)}/>
        {/* Delete data modal */}
        <Modal
          closeButton
          aria-labelledby="modal-title"
          open={deleteModalVisible}
          onClose={() => setDeleteModalVisible(false)}
        >
          <Modal.Header>
            <Text id="modal-title" size={18}>
              Eliminar
              <span style={{margin: 5}}></span>
              <Text b size={18}>
                {props.asset}
              </Text>
            </Text>
          </Modal.Header>
          <Modal.Body>
            <Text>Se eliminarán todas las velas en la base de datos local, así como formatos exportados para <Text b transform="capitalize">{props.asset}</Text></Text>
          </Modal.Body>
          <Modal.Footer>
            <Button auto onPress={() => setDeleteModalVisible(false)}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Download modal */}
        <Modal
          closeButton
          aria-labelledby="modal-title"
          open={dataNotDownloadedModalVisible}
          onClose={() => setDataNotDownloadedModalVisible(false)}
          width="700px"
        >
          <Modal.Header>
            <Text id="modal-title" size={18}>
              No hay datos descargados de
              <span style={{margin: 5}}></span>
              <Text b size={18}>
                {props.asset}
              </Text>
            </Text>
          </Modal.Header>
          <Modal.Body>
            <Text>Es necesario descargar los datos <Text b transform="capitalize">{props.asset}</Text> para convertilos a otro formato. ¿Deseas descargar los datos?</Text>
            <Grid style={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 15}}>
              <Button onPress={() => {
                setDataNotDownloadedModalVisible(false)
                // handleDropdownChange('download')

                }
              }>
                Descargar todo
              </Button>
              <Spacer y={1}/>
              <Button onPress={() => {
                setDataNotDownloadedModalVisible(false)
                setDownloadModalVisible(true)
                }
              }>
                Descargar por fechas
              </Button>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Button auto ghost color="error" onPress={() => {
              setDataNotDownloadedModalVisible(false)
              }}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </Grid>
    </div>
  )

}
