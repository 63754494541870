/* tslint:disable */
/* eslint-disable */
/**
 * Quandle Backend OpenAPI 3.0
 * This is the Quantdle Backeed that allows you to download historic data
 *
 * OpenAPI spec version: 0.0.1
 * Contact: support@quantdle.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

 /**
 * 
 *
 * @export
 * @interface Instrument
 */
export interface Instrument {

    /**
     * The name of the instrument
     *
     * @type {string}
     * @memberof Instrument
     * @example EURUSD
     */
    instrumentName?: string;

    /**
     * The asset type
     *
     * @type {string}
     * @memberof Instrument
     * @example FOREX
     */
    assetClass?: InstrumentAssetClassEnum;

    /**
     * The asset type
     *
     * @type {string}
     * @memberof Instrument
     * @example MAJORS
     */
    subAssetClass?: InstrumentSubAssetClassEnum;

    /**
     * The date from which the system has data avaiable for this instrument
     *
     * @type {string}
     * @memberof Instrument
     * @example Wed Jun 28 00:00:00 GMT 2023
     */
    dataAvailableFrom?: string;

    /**
     * The date until which the system has data avaiable for this instrument
     *
     * @type {string}
     * @memberof Instrument
     * @example Thu Jun 29 00:00:00 GMT 2023
     */
    dataAvailableUntil?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum InstrumentAssetClassEnum {
    FOREX = 'FOREX',
    EQUITIES = 'EQUITIES',
    COMMODITIES = 'COMMODITIES'
}
/**
 * @export
 * @enum {string}
 */
export enum InstrumentSubAssetClassEnum {
    MAJORS = 'MAJORS',
    MINORS = 'MINORS',
    CROSSES = 'CROSSES',
    EXOTICS = 'EXOTICS',
    INDICES = 'INDICES',
    RECIOUSMETALS = 'RECIOUS_METALS'
}

