import { Grid, Input, Button, Badge, Checkbox } from '@nextui-org/react';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { BsSearch } from 'react-icons/bs';
import { QuantdleLoader } from '../components/quantdle-loader/loader.component';
import { SymbolCompoent } from '../components/symbols/symbol.component';
import {  Instrument, InstrumentAssetClassEnum } from '../shared/api';
import { useAppDispatch, useAppSelector } from '../shared/hooks/redux.hook';
import { AssetType, assetTypes } from '../shared/constants/symbol.constants';
import { Chip } from '../components/symbols/chip';
import styles from './symbols.module.css'
import DownloadModalComponent from '../components/download-date-modal/download-modal.component';
import { DateRange } from 'react-day-picker';
import { CreateDownloadRequestPlatformEnum, CreateDownloadRequestSpreadEnum } from '../shared/download-api/models';
import { addJobStatus } from '../shared/redux/job-status-slice';
import { JobStatusEnum, TaskStatusEnum } from '../shared/models/quantdle-job-status-update';
import { updateUsage } from '../shared/redux/usage-slice';
import { useQuantdleApi } from '../shared/hooks/quantdle-api.hook';
import { PlanModalComponent } from '../components/plan-modal/plan-modal.component';

interface ChipStatus extends AssetType{
  active: boolean
}

interface SubAssetChipStatus {
  label: string,
  value: string,
}

interface SelectedAssetStatus {
  selected: boolean,
  asset: string
}

interface Symbol extends Instrument{
  show: boolean
  selected: boolean
} 


export const SymbolsPage = () => {
  const dispatch = useAppDispatch()
  const usage = useAppSelector(state => state.usage)

  const { instrumentsApi, downloadsApi} = useQuantdleApi()

  const [instruments, setInstruments] = useState<Instrument[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedAssets, setSelectedAssets] = useState<SelectedAssetStatus[]>([])
  const [chipFilterState, setChipFilterState] = useState<ChipStatus[]>(assetTypes.map(item => { return {...item, active: true}}))
  const [subAssetChip, setSubAssetChip] = useState<SubAssetChipStatus | undefined>()
  const [modalFromDate, setModalFromDate] = useState<Date | undefined>()
  const [modalToDate, setModalToDate] = useState<Date | undefined>()
  const [showPlanModal, setShowPlanModal] = useState<boolean>(false)

  const filteredInstruments: Symbol[] = useMemo(() => {

    const activeAssetTypes = chipFilterState.filter(item => item.active).map(item => item.value)
    const symbols = instruments.map(item => {return {...item, show: false, selected: false}})
    symbols.filter(item => activeAssetTypes.includes(item.assetClass.toLowerCase())).filter(item => item.instrumentName.toLowerCase().startsWith(searchTerm.toLowerCase())).forEach(item => { item.show = true} )
    
    // if a subAssetChip is selected, show only the assets with that subAssetClass
    if(subAssetChip){
      symbols.filter(item => item.subAssetClass?.toLowerCase() !== subAssetChip.value.toLowerCase()).forEach(item => { item.show = false} )
    }

    // if a item has been selectedm highlight it
    symbols.forEach((item) => {
      const el = selectedAssets.find((el) => el.asset === item.instrumentName)
      if(el){
        item.selected = el.selected
      }else{
        item.selected = false
      }
      
    })
    return symbols
  }, [instruments, chipFilterState, searchTerm, selectedAssets, subAssetChip])
  

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const getInstruments = () => {

    if(!instrumentsApi) return

    instrumentsApi.getInstruments().then((value => {
        // short instrument by assetType
        value.data.sort((a, b) => {
          if(!a.assetClass){
            a.assetClass = InstrumentAssetClassEnum.FOREX
          }
          if(!b.assetClass){
            b.assetClass = InstrumentAssetClassEnum.FOREX
          }

          return a.assetClass.charCodeAt(0) - b.assetClass.charCodeAt(0)

        })
        setInstruments(value.data)
        setIsLoading(false)
      }), error => {
        setIsLoading(false)
        console.log(error)
        toast.error(`An error ocurred when retrieving instruments: ${error}`)
      })
    }

  useEffect(() => {
    // retrieve the instruments from the backend
    setIsLoading(true)
    getInstruments()
  }, [instrumentsApi])


  

  const [downloadModalVisible, setDownloadModalVisible] = useState(false);



  const handleSearch = (searchTerm: string) => {
      // remove selected items
      setSelectedAssets([])
      setSubAssetChip(undefined)
      setSearchTerm(searchTerm)

  };


  const handleOnFilterChipClick = (label: string, value: string) => { 

    // remove selected items
    setSelectedAssets([])
    setSubAssetChip(undefined)
    const chip = chipFilterState.find((item) => item.value === value)
    chip.active = !chip.active
    if(chipFilterState.filter(item => item.active).length > 0){
      chip.color = chip.active ? assetTypes.find(item => item.value === chip.value)?.color  : "default" 
      setChipFilterState([...chipFilterState]) // need to clone the array , if not react does not detect change (the reference of the array havent changed)-> https://stackoverflow.com/questions/25937369/react-component-not-re-rendering-on-state-change
    }else{
      // this case occurs when all chips have been disabled and the user is trying to disable the last one, do not disable as it would present an empty instrument list
      chip.active = true
    }
  }

  const handleSymbolAssetTypeClick = (label: string, value: string) => {

    // in this case when selected in the symbol component, the asset must be the only one to shoy
    const newState: ChipStatus[] = chipFilterState.map((item) => {
      if(item.value.toLowerCase() !== value.toLowerCase()){
        return {...item, active: false, color: "default"}
      }else{
        return item
      }
    })

    setChipFilterState([...newState])

  }

  const handleSymbolSubAssetTypeClick = (assetType: string, subAssetClass: string) => {
    // set the filter chips to the assetType passed and the subAssetClass to the subAssetClass passed
    const newState: ChipStatus[] = chipFilterState.map((item) => {
      if(item.value.toLowerCase() !== assetType.toLowerCase()){
        return {...item, active: false, color: "default"}
      }else{
        return item
      }
    })
    setChipFilterState([...newState])

    // show only the assets with the subAssetClass passed
    
    setSubAssetChip({label: subAssetClass.substring(0,2), value: subAssetClass.toLowerCase()})


  }

  const handleOnAssetClick = (asset: string) => {

    const assetItem = selectedAssets.find((item) => item.asset === asset)

    if(!assetItem){
      selectedAssets.push({
        asset: asset,
        selected: true
      })
    }else{
      // toggle asset
      assetItem.selected = !assetItem.selected
    }
    setSelectedAssets([...selectedAssets])
  }

  const handleOnDownloadClick = () => {

    // check that user's plan includes custom downloads
    if(usage.maxCustomDownloads === 0 || usage.customDownloads >= usage.maxCustomDownloads){
      setShowPlanModal(true)
      return
    }

    // open modal and update the fromDate and toDate accourding to the assets that are selected
    const selectedAssets = filteredInstruments.filter(item => item.selected)
    const fromDate = new Date(Math.max(...selectedAssets.map(item => new Date(item.dataAvailableFrom.split('T')[0]).getTime())))
    const toDate = new Date(Math.min(...selectedAssets.map(item => new Date(item.dataAvailableUntil.split('T')[0]).getTime())))

    setModalFromDate(fromDate)
    setModalToDate(toDate)
    setDownloadModalVisible(true)
  }

  const handleOnModalDownloadClick = (dateRange: DateRange, selectedFormat: string) => {
    // download the assets
    const selectedAssets = filteredInstruments.filter(item => item.selected)
    const fromDate = dateRange.from
    const toDate = dateRange.to
    const assetNames = selectedAssets.map(item => item.instrumentName)
    setDownloadModalVisible(false)
    const id = toast.loading('Preparing download...')
    downloadsApi.createPost({
      symbols: assetNames,
      platform: selectedFormat as CreateDownloadRequestPlatformEnum ?? CreateDownloadRequestPlatformEnum.MT4,
      dateFrom: fromDate,
      dateUntil: toDate,
      spread: CreateDownloadRequestSpreadEnum.MEAN,
    }).then((res) => {
      toast.success('Download queued')
      toast.dismiss(id)

      // set new job status
      dispatch(addJobStatus({
        jobId: res.data.jobId,
        status: JobStatusEnum.PENDING,
        dataFrom: fromDate.toDateString(),
        dataTo: toDate.toDateString(),
        createdOn: new Date().toDateString(),
        taskStatusUpdates: selectedAssets.map((item) => {
          return {
            symbol: item.instrumentName,
            status: TaskStatusEnum.PENDING
          }
        })
      }))

      // add 1 to the custom dowloads usage
      dispatch(updateUsage({
        customDownloads: usage.customDownloads + 1
      }))


    }, error => {
      console.log(error)
      toast.dismiss(id)
      toast.error(`An error ocurred when queuing the assets: ${error}`)
    })
  }

  if(!instrumentsApi) return (<QuantdleLoader />)

  return (

    <>
      <Grid.Container gap={1}  style={{display:"flex", alignItems:"center", marginBottom: 25, paddingLeft: 50, paddingRight: 50}}>
        <Grid xs={12} style={{display: 'flex', justifyContent:'flex-start'}}>
          <Input
            type="text"
            width="300px"
            shadow={false}
            animated={false}
            css={{ $$inputColor: "var(--nextui-colors-inputColor)" }}
            placeholder="Type what you are looking for..."
            aria-label="search asset"
            contentRight={<BsSearch fill="var(--nextui-colors-primary)" />}
            style={{}}
            clearable
            onChange={(e) => {
              if(e.type === 'click'){
                // this is the clear command
                handleSearch(e.target.value);  
              }else{
                e.preventDefault();
                handleSearch(e.target.value);
              }
            }}
          />

          <div style={{display: 'flex', justifyContent: 'center', marginLeft: 10, marginRight: 10}} className={styles.filters}>
            {chipFilterState.map((item) =>
              <div style={{marginLeft: 5, marginRight: 5, display: 'flex', alignContent: 'center'}} > 
                <Chip onClick={handleOnFilterChipClick} value={item.value} label={item.label} color={item.color}></Chip>
              </div>
            )}
          <div style={{minWidth: 75,  display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
            
              <div  onClick={() => setSubAssetChip(undefined)} className={subAssetChip ? styles['scale-in-center'] : styles['scale-out-center']} style={{display: 'flex', alignContent: 'center', justifyContent: 'center', userSelect: 'none', cursor: 'pointer'}}>
                <Badge size={'xs'} placement={'top-right'} verticalOffset="20%" color={"error"} content='x' disableOutline>
                  <Chip value={subAssetChip?.value ?? ''} label={subAssetChip?.label ?? ''} color='success'></Chip>
                </Badge>
              </div>
            
          </div>
          {

            selectedAssets.find(item => item.selected === true) ? 
            <Badge disableOutline content={
              selectedAssets.filter(item => item.selected === true).length
            } color={'error'} style={{userSelect: "none"}} size="sm">
              <Button auto rounded onPress={() => handleOnDownloadClick()}>Download</Button>
            </Badge> : <Button disabled auto rounded>Download</Button>

          }
          </div>
          
        </Grid>
      </Grid.Container>
      <Grid xs={3} style={{display: "flex", userSelect: 'none'}} justify='center'>
        <Checkbox
          isDisabled={!filteredInstruments.some(item => item.show === false)} 
          isSelected={selectedAssets.filter(item => item.selected).length > 0}
          isIndeterminate={selectedAssets.filter(item => item.selected).length < filteredInstruments.length && selectedAssets.filter(item => item.selected).length > 0} 
          onChange={(isSelected) => {
          // assign all assets to selected
            const newSelectedAssets = filteredInstruments.filter(item => item.show).map(item => {return {asset: item.instrumentName, selected: isSelected}})
            setSelectedAssets([...newSelectedAssets])
          }}>Select all</Checkbox>
      </Grid>
      <Grid.Container gap={1} >
        { isLoading ? <Grid xs={12} justify='center' alignItems='center' style={{height: 500}}><QuantdleLoader></QuantdleLoader></Grid> :
        <>
        <Grid
          xs={12}
          style={{ display: 'flex', flexWrap: 'wrap' }}
          justify="center"
        >
          {filteredInstruments.map((asset, index) => (
              <SymbolCompoent
                key={index}
                subAssetClass={asset.subAssetClass ?? ''}
                asset={asset.instrumentName ?? ''}
                assetType={asset.assetClass ?? ''}
                onAssetClick={handleOnAssetClick}
                onAssetTypeClick={handleSymbolAssetTypeClick}
                onSubAssetTypeClick={handleSymbolSubAssetTypeClick}
                hide={!asset.show}
                highlight={asset.selected}
              />
            ))}
        </Grid>
        <DownloadModalComponent
          fromDate={modalFromDate}
          toDate={modalToDate}
          visible={downloadModalVisible}
          instrumentNames={selectedAssets.filter(item => item.selected).map(item => item.asset)}
          onDownload={handleOnModalDownloadClick}
          onClose={() => setDownloadModalVisible(false)}
        />
        </>
        }
      </Grid.Container>
      <PlanModalComponent visible={showPlanModal} onClose={() => {setShowPlanModal(false)}}/>
    </>
  );
};
