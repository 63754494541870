import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './sidenav.module.css'
import { Tooltip } from '@nextui-org/react'


export const SidenavElement = (props: {icon: ReactElement, title: string, link: string, disabled?: boolean, onClick?: (title: string) => void}) => {
    const navigate = useNavigate()

    const handleClick = () => {
      if(!props.disabled){
        navigate(props.link)

        if(props.onClick){
          props.onClick(props.title)
        }

      }
    }

    return (
      
        <div className={props.disabled ? styles.disabled : styles.sideNavElement} onClick={handleClick}>
          <Tooltip content={props.title} placement='right' color={'secondary'}>
            {props.icon}
          </Tooltip>
        </div>
      
    )
}
