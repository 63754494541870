import { Badge, Button, Spacer, Table, Text } from "@nextui-org/react"
import { useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useNotifications } from "../shared/hooks/notifications.hook"


export interface StringIndexable {
    key: string
    [key: string]: any
}

export const NotificationsPage = () => {

    const dispatch = useDispatch()
    const {notifications, deleteNotification, markNotificationAsRead}  = useNotifications()

    const [selectedNotifications, setSelectedNotifications] = useState<StringIndexable[]>([])

    const rows: StringIndexable[] = useMemo(() => { return notifications.map((item, key) => {
        return {
            key: key.toString(),
            title: item.title ?? '',
            message: item.message ?? '',
            isRead: item.isRead,
            datetime: new Date(item.datetime).toLocaleString(),
            notificationId: item.notificationId
        }
    })
    }, [notifications])

    rows.sort((a, b) => {
        const aTime = new Date(b.datetime).getTime() 
        const bTime = new Date(a.datetime).getTime()
        return aTime - bTime
        }
    )

    const columns = [
        {
            key: "title",
            label: "Title",
        },
        {
            key: "message",
            label: "Message",
        },
        {
            key: "datetime",
            label: "Event Datetime"
        },
        {
            key: "isRead",
            label: "Status",
        },
        
    ];

    const handleMarkAsRead = () => {
        // dispatch mark as read notificatin for all selected notifications
        selectedNotifications.forEach((item) => {markNotificationAsRead(item.notificationId)})
        
    }

    const handleDelete = () => {
        // dispatch delete notification for all selected notifications
        selectedNotifications.forEach((item) => {deleteNotification(item.notificationId)})
    }


    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '15px'}}>
            <div style={{justifyContent: 'flex-start', width: '100%'}}>
                <Text h2>Notifications</Text>
            </div>
            <div style={{display: 'flex', marginBottom: 20, justifyContent: 'flex-start', width: '100%'}}>
                <Button
                    onPress={handleMarkAsRead} 
                    auto 
                    disabled={selectedNotifications.length === 0}  
                    color="primary" >Mark as read</Button>
                <Spacer></Spacer>
                <Button onPress={handleDelete} auto disabled={selectedNotifications.length === 0} ghost color="error">Delete</Button>
            </div>
            <div style={{width: '100%'}}>
                {/* unread notifications */}
                <Table
                    aria-label="Example table with dynamic content"
                    css={{
                        height: "auto",
                        width: "100%",
                        userSelect: "none",
                    }}
                    bordered
                    color={'secondary'}
                    selectionMode="multiple"
                    hoverable
                    onSelectionChange={(keys) => {
                            if(keys === 'all'){
                                setSelectedNotifications(rows)
                            }else{
                                setSelectedNotifications(rows.filter((item) => keys.has(item.key)))
                            }
                        }
                    }
                >
                <Table.Header columns={columns}>
                    {(column) => (
                        <Table.Column key={column.key}>{column.label}</Table.Column>
                    )}
                </Table.Header>
                <Table.Body items={rows}>
                    {(item: any) => (
                    <Table.Row key={item.key}>
                        {(columnKey: any) => {

                                if(columnKey === 'isRead'){
                                    return <Table.Cell>{item[columnKey] ? <Badge variant={'flat'} disableOutline color={'success'}>Read</Badge> : <Badge disableOutline variant={'flat'} color={'warning'}>New</Badge> }</Table.Cell>
                                }

                                return <Table.Cell>{item[columnKey]}</Table.Cell>
                            }
                        }
                    </Table.Row>
                    )}
                </Table.Body>
                </Table>
            </div>
            { rows.length === 0 &&
                <div>
                    <Text h4 b>You have no new notifications</Text>
                </div>
            }
            
        </div>
    )


}