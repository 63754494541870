import { Badge, Button } from '@nextui-org/react'
import { Colors } from '../symbols/chip'
import { formatFileSize } from '../../shared/utils/utils'
import { useState } from 'react'

export const OneClickDownloadButtonComponent = (props: {assetClass: string, disabled?: boolean, fileSize: number, year: string,  colors?: Colors, onClick?: (assetClass: string, year: string) => void}) => { 

    const handleClick = () => {
        if(props.onClick && !props.disabled){
            props.onClick(props.assetClass, props.year)
        }
    }

    const capitalize = (s: string) => {

        // apply regrex to replace anty separator by a space
        s = s.toLowerCase().replace(/[,\s;./:\t-_]+/g, ' ');

        // split by spaces and capitalize
        s = s.split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')

        return s
    }

    if(props.disabled){
        return (
            <Button auto flat disabled={props.disabled} color={props.colors}>
                {capitalize(props.assetClass)}
            </Button>
        )
    }

    return (
        <Badge disableOutline color={props.colors} size={'xs'} content={formatFileSize(props.fileSize, 0)}>
            <Button auto flat onPress={handleClick} color={props.colors}>
                {capitalize(props.assetClass)}
            </Button>
        </Badge>
    )
}