import { Modal, Text, Input, Badge, Button, Progress, Link, Card } from "@nextui-org/react"
import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import { useAppSelector } from "../../shared/hooks/redux.hook";
import { formatFileSize } from "../../shared/utils/utils";
import { WarningIcon } from "../icons/warning-icon.component";
import styles from './one-click-download-modal.module.css'
import { PlanModalComponent } from "../plan-modal/plan-modal.component";
import { useState } from "react";


export const OneClickModalComponent = (props: {visible: boolean, assetClass: string, instruments: string[], year: number, fileSize: number, onClose?: () => void, onDownload?: (assetClass: string, year: number) => void }) => {

    const usage = useAppSelector(state => state.usage)
    const [showPlanModal, setShowPlanModal] = useState<boolean>(false)
    const isGreaterThanAllowed = Number(usage.bandwith) + Number(props.fileSize) > Number(usage.maxBandwith)
    const isFreeUser = usage.plan === 'free'
    const isAllOrRecent2Years = props.year.toString() === '-1' || new Date().getFullYear() - props.year <= 1

    const handleOnDownload = (assetClass: string, year: number) => {
        if (props.onDownload){
            props.onDownload(assetClass, year)
        }
    }

    return (
        <Modal
            scroll 
            width="600px"
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={props.visible}
            onClose={props.onClose}
        >
        <Modal.Header>
            <Text h3 id="modal-title">
                {`Download ${props.assetClass} ${props.year} data`}
            </Text>
        </Modal.Header>
        <Modal.Body>
            <Card.Divider />
            <Text h4 style={{userSelect: 'none'}}>Instruments</Text>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {props.instruments?.map(item => item.toUpperCase()).map(
                            item => {
                                return <Badge disableOutline style={{margin: 2}} variant='flat' color={'success'}>{item}</Badge>
                            }
                        )}
            </div>
            <Card.Divider />
            <Text h4 style={{userSelect: 'none'}}>Data Range</Text>
            <Text>{props.year === -1? `From 2003 to ${new Date().getFullYear()}` : `All data for ${props.year}`}</Text>
            {/* <Input 
                initialValue={props.year === -1? `From 2003 to ${new Date().getFullYear()}` : `All data for ${props.year}`}
                css={{ $$inputColor: "var(--nextui-colors-inputColor)" }}
                readOnly
            /> */}
            <Card.Divider />
            <Text h4 style={{userSelect: 'none'}}>Bandwith usage after download</Text>
            <Text color={isGreaterThanAllowed ? "error" : "secondary"} h4 css={{lineHeight: "$xs", margin: 0, display: 'flex', alignItems: 'flex-end' }}>
                {formatFileSize(usage.bandwith, 0)} + {formatFileSize(props.fileSize, 0)} = {formatFileSize(Number(usage.bandwith) + Number(props.fileSize), 0)} <Text small css={{ color: "$accents5" }}>/ {formatFileSize(usage.maxBandwith, 0)}</Text>
            </Text>
            { isFreeUser && isAllOrRecent2Years &&
                <div style={{display: 'flex', textAlign: 'justify', userSelect: 'none', marginTop: 15}} className={styles.warning}>
                <div style={{display: 'flex', alignItems: 'center', marginRight: 25}}><WarningIcon size={32}/></div>
                    <Text color="currentColor"> FREE Accounts are not allowed to download latests data ​☹️​.</Text>
                </div>
            }
            {isGreaterThanAllowed && 
                <div style={{display: 'flex', textAlign: 'justify', userSelect: 'none', marginTop: 15}} className={styles.warning}>
                    <div style={{display: 'flex', alignItems: 'center', marginRight: 25}}><WarningIcon size={32}/></div>
                    <Text color="currentColor"> You won't be able to download this file, as it will hit your monthly bandwidth limit 😲.</Text>
                </div>
            }
            {
                (isGreaterThanAllowed  || (isFreeUser && isAllOrRecent2Years)) &&
                <>
                <Text h4><Link color={'primary'} underline onPress={() => setShowPlanModal(true)}>Update your plan</Link><span> to get more files! 😌​</span></Text>
                </>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button auto flat onPress={props.onClose} color="error">
                Cancel
            </Button>
            <Button disabled={(isGreaterThanAllowed  || (isFreeUser && isAllOrRecent2Years))} onPress={() => handleOnDownload(props.assetClass, props.year)} auto>
                Download
            </Button>
        </Modal.Footer>
            <PlanModalComponent visible={showPlanModal} onClose={() => setShowPlanModal(false)}/>
        </Modal>
        
    )

}