import { Button, Modal, Text } from "@nextui-org/react"
import { useQuantdleApi } from "../../shared/hooks/quantdle-api.hook"
import { PlanModalComponent } from "../plan-modal/plan-modal.component"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { AxiosError } from "axios"


export const EAModal = (props: {visible?: boolean, onClose?: () => void,}) => {

    const {eaApi} = useQuantdleApi()
    const [showPlanModal, setShowPlanModal] = useState<boolean>(false)

    const handleOnClose = () => {
        if(props.onClose){
            props.onClose()
        }
    }

    const handleDownload = () => {
        // try to start download
        const id = toast.loading("Downloading EA...")

        eaApi.downloadEA().then((res) => {

            if(res.status === 200){

                const link = document.createElement('a');
                // set the link element href to the blob url
                link.href = res.data?.downloadUrl ?? "";
                
                // simulate a click on the link element
                link.click();
                link.remove();
                handleOnClose()
                toast.dismiss(id)
                toast.success("EA downloaded successfully!, check your downloads")
            }
        }
        ).catch((reason: AxiosError) => {
            toast.dismiss(id)
            if(reason.response.status === 401){
                // show plan modal
                toast.error("Your current plan does not allow to download the EA.")
                setShowPlanModal(true)
            }else{
                toast.error("Something went wrong.")
                handleOnClose()   
            }
        })
    }
    useEffect(() => {
        if(props.visible){
            handleDownload()
        }
    }, [props.visible])
    return (
        <PlanModalComponent 
                visible={showPlanModal}
                onClose={() => setShowPlanModal(false)}
                title="Ooops! It seems this feature its outside you plan."
            />
    )

    // as we dont have the video yet, use this component to just call the download
    return (
        <Modal
            scroll
            width="650px"
            // fullScreen
            preventClose
            closeButton
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={props.visible}
            style={{zIndex: 1000}}
            onClose={props.onClose}
        >
        <Modal.Header>
            <Text id="modal-title" size={18}>
                {'Download Quantdle MT5 EA'}
            </Text>
        </Modal.Header>
        <Modal.Body
            css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Text>Check the Quantdle MT5 EA funtionality with the next showcase video:</Text>
            <iframe width="560" style={{borderRadius: 14}} height="315" src="https://www.youtube.com/embed/pe6FwPx8z3c?si=gGl3r88iwIFdd9NF" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" frameBorder="0" allowFullScreen></iframe>
            <PlanModalComponent 
                visible={showPlanModal}
                onClose={() => setShowPlanModal(false)}
                title="Ooops! It seems this feature its outside you plan."
            />
        </Modal.Body>
        <Modal.Footer>
            <Button auto onPress={handleDownload}>
                Download
            </Button>
            <Button flat auto onPress={handleOnClose}>
                Close
            </Button>
        </Modal.Footer>
        </Modal>
    )

}