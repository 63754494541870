import { set } from 'date-fns';
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { QuantdleNotification } from '../models/quantdle-notification';



export interface NotificationState {
    notifications: QuantdleNotification[]
}

const initialState: NotificationState = {
    notifications: []
}


export const notificationSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        setNotifications: (state, notifications: PayloadAction<QuantdleNotification[]>) => {
            state.notifications = notifications.payload
        },
        addNotification: (state, notification: PayloadAction<QuantdleNotification>) => {
            state.notifications.push(notification.payload)
        },
        removeNotification: (state, notificationId: PayloadAction<string>) => {
            state.notifications = state.notifications.filter(notification => notification.notificationId !== notificationId.payload)
        },
        markAsRead: (state, notificationId: PayloadAction<string>) => {
            state.notifications = state.notifications.map(notification => {
                if (notification.notificationId === notificationId.payload) {
                    notification.isRead = true
                }
                return notification
            })
        }
    }
})


export const { setNotifications, addNotification, removeNotification, markAsRead } = notificationSlice.actions