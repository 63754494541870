import { Button, Modal, Text } from "@nextui-org/react";
import { DateRangePicker, DataRangePickerProps } from "../date-range-picker/date-range-picker.component";
import { useState } from "react";
import { DateRange } from "react-day-picker";


export interface DownloadDateModalComponentProps {
    visible?: boolean,
    onClose?: () => void,
    onSelect?: () => void
}

export default function DownloadDateModalComponent(props: DownloadDateModalComponentProps & DataRangePickerProps){

  const [dateRange, setDateRange] = useState<DateRange>()

  return (
    <Modal
        closeButton
        preventClose
        aria-labelledby="modal-title"
        open={props.visible}
        onClose={props.onClose}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            Data range
          </Text>
        </Modal.Header>
        <Modal.Body>
          <DateRangePicker fromDate={props.fromDate} toDate={props.toDate} onRangeChange={(setDateRange)}></DateRangePicker>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat color="error" onClick={props.onClose}>
            Close
          </Button>
          <Button auto onClick={props.onSelect}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>
  )

}
