import { QuantdleCodeEditor } from "../quantdle-code-editor/quantdle-code-editor.component"



export const MlCodeEditorComponent = (props: {symbol: string, zipCode: boolean, statistics: boolean, plotData: boolean}) => {


    const baseCode = `import pandas as pd
import matplotlib.pyplot as plt 

def from_csv(symbol: str) -> pd.DataFrame:
    df = pd.read_csv(f'{symbol}.csv')
    # Convert 'date' and 'time' columns to datetime and set it as the index
    df['datetime'] = pd.to_datetime(df['date'] + ' ' + df['time'])
    df.set_index('datetime', inplace=True)
    df.drop(columns=['date', 'time'], inplace=True)
    return df

# Define the filename
symbol = '${props.symbol.toUpperCase()}'

# Read the file
df = from_csv(symbol)
`

    const zipCode = `import pandas as pd
import matplotlib.pyplot as plt 
import zipfile 

def from_csv(symbol: str) -> pd.DataFrame:
    df = pd.read_csv(f'{symbol}.csv')
    # Convert 'date' and 'time' columns to datetime and set it as the index
    df['datetime'] = pd.to_datetime(df['date'] + ' ' + df['time'])
    df.set_index('datetime', inplace=True)
    df.drop(columns=['date', 'time'], inplace=True)
    return df

def from_zip(symbol: str):
    
    # Extracting the CSV file from the ZIP
    with zipfile.ZipFile(f'ML_{symbol}_-1.zip', 'r') as zip_ref:
        zip_ref.extract(f'{symbol}.csv')
    
    return from_csv(symbol)

# Define the filename
symbol = '${props.symbol.toUpperCase()}'

# Read the file
df = from_zip(symbol) # from_csv(symbol)` 

    const statisticsCode = `

# Group data by day
daily_data = df.resample('D').last().dropna() # drop NaN as weekend data does not exist.

# compute moving average crossover 
slow_window = 50
fast_window = 20

daily_data['slow_mean'] = daily_data['close'].rolling(window=slow_window).mean()
daily_data['fast_mean'] = daily_data['close'].rolling(window=fast_window).mean()

# compute bolinger bands over fast mean
daily_data['std'] = daily_data['close'].rolling(window=fast_window).std()
daily_data['bb_upper'] = daily_data['fast_mean'] + daily_data['std'] * 2
daily_data['bb_lower'] = daily_data['fast_mean'] - daily_data['std'] * 2
`

const plotCode = `
# Plotting
plt.figure(figsize=(10,6))
plt.plot(daily_data.index, daily_data['close'])
plt.title('Daily Close Price')
plt.xlabel('Date')
plt.ylabel('Close Price')
plt.grid(True)
plt.legend(['Close Price'])
plt.show()`

const plotCodeWithStatistics = `
# Plotting
plt.figure(figsize=(10,6))
plt.plot(daily_data.index, daily_data['close'])
plt.plot(daily_data.index, daily_data['slow_mean'])
plt.plot(daily_data.index, daily_data['fast_mean'])
plt.plot(daily_data.index, daily_data['bb_upper'], color='gray', linestyle='--')
plt.plot(daily_data.index, daily_data['bb_lower'], color='gray', linestyle='--')
plt.title('Daily Close Price')
plt.xlabel('Date')
plt.ylabel('Close Price')
plt.grid(True)
plt.legend(['Close Price', 'Slow Mean', 'Fast Mean', 'Upper BB', 'Lower BB'])
plt.show()`


const getCode = () => {

    let code = baseCode

    if(props.zipCode) {
        code = zipCode
    }

    if(props.statistics) {
        code += statisticsCode
    }

    if(props.plotData && props.statistics) {
        code += plotCodeWithStatistics
    }

    if(props.plotData && !props.statistics) {
        code += plotCode
    }

    return code
}


return <QuantdleCodeEditor title="Quick start python code" code={getCode()}  downloadName={`python_code_${props.symbol}.py`}/>

}