import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { JobStatusUpdate } from "../models/quantdle-job-status-update"


const initialState: JobStatusUpdate[] = []


export const jobStatusSlice = createSlice({
    name: "jobStatus",
    initialState,
    reducers: {
        addJobStatus: (state, jobStatus: PayloadAction<JobStatusUpdate>) => {
            // first check if the job status already exists
            const jobStatusUpdate = state.find(jobStatusUpdate => jobStatusUpdate.jobId === jobStatus.payload.jobId)
            if (jobStatusUpdate) {
                // if it does, update it
                jobStatusUpdate.status = jobStatus.payload.status
                jobStatusUpdate.taskStatusUpdates = jobStatus.payload.taskStatusUpdates
                jobStatusUpdate.fileSize = jobStatus.payload.fileSize

            } else {
                // if it doesn't, add it
                state.push(jobStatus.payload)
            }

        },
        removeJobStatus: (state, jobStatusId: PayloadAction<string>) => {
            const jobStatusIndex = state.findIndex(jobStatusUpdate => jobStatusUpdate.jobId === jobStatusId.payload)
            if (jobStatusIndex !== -1) {
                state.splice(jobStatusIndex, 1)
            }
        },
    }
})




export const { addJobStatus, removeJobStatus, } = jobStatusSlice.actions