
import { useDispatch } from 'react-redux';
import { useAppSelector } from './redux.hook';
import { markAsRead, removeNotification } from '../redux/notification-slice';
import { useQuantdleApi } from './quantdle-api.hook';



export const useNotifications = () => {

    const dispatch = useDispatch();
    const notifications = useAppSelector((state) => state.notification.notifications)
    const { websocketApi } = useQuantdleApi()

    const markNotificationAsRead = (notificationId: string) => {
        dispatch(markAsRead(notificationId))
        websocketApi.markNotificationAsRead(notificationId)
    }

    const deleteNotification = (notificationId: string) => {
        dispatch(removeNotification(notificationId))
        websocketApi.deleteNotification(notificationId)
    }


    return { notifications, markNotificationAsRead, deleteNotification }
}