import { Switch, useTheme, changeTheme } from "@nextui-org/react"
import { useTheme as useNextTheme } from 'next-themes'
import { CiDark } from "react-icons/ci";
import { CiLight } from "react-icons/ci";

export const SwitchThemeComponent = () => {

    const {isDark, type} = useTheme()
    const { setTheme } = useNextTheme();
    
    return <Switch
        shadow
        size={'lg'}
        color={'secondary'}
        checked={isDark}
        onChange={(e) => setTheme(e.target.checked ? 'dark' : 'light')}
        iconOn={<CiDark size={20} />}
        iconOff={<CiLight size={20} />}
    />

}