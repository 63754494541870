import { Grid, Button, Text, Input } from "@nextui-org/react"
import {  useEffect, useMemo, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../shared/hooks/redux.hook"
import { QuantdleLoader } from "../quantdle-loader/loader.component"
import { PredefinedDownloadsResponse } from "../../shared/download-api/models"
import { OneClickDownloadRowComponent } from "./one-click-download-row.component"
import styles from './one-click-download.module.css'
import { OneClickModalComponent } from "./one-click-download-modal.component"
import { Instrument} from "../../shared/api"
import toast from "react-hot-toast"
import { updateUsage } from "../../shared/redux/usage-slice"
import { useQuantdleApi } from "../../shared/hooks/quantdle-api.hook"
import { SymbolCompoent } from "../symbols/symbol.component"
import { MLSymbolComponent } from "../symbols/ml-symbol.component"
import { InfoComponent } from "../info/info.component"
import { BsSearch } from "react-icons/bs"

const group_by_year = (data: PredefinedDownloadsResponse) => {
    const result: {[key: string]: PredefinedDownloadsResponse} = {}
    data.forEach((value) => {
        if(!result[value.year]){
            result[value.year] = []
        }

        result[value.year].push(value)
    })
    return result
}



export const OneClickDownloadComponent = (props: {onClick?: (assetClass: string, year: string) => void}) => {

    const dispatch = useAppDispatch()
    const usage = useAppSelector(state => state.usage)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showModal , setShowModal] = useState<boolean>(false)
    const [selectedAssetClass, setSelectedAssetClass] = useState<string>('ALL')
    const [selectedYear, setSelectedYear] = useState<number>(2021)
    const [instruments, setInstruments] = useState<Instrument[]>([])
    const downloadInstruments = useMemo(() => {

        if (selectedAssetClass === 'ALL'){
            return instruments.map((item) => item.instrumentName)
        }
        // filter and return the instruments for the selected asset class
        const filtered = instruments.filter((item) => item.assetClass === selectedAssetClass || item.subAssetClass === selectedAssetClass)
        return filtered.map((item) => item.instrumentName)

    }, [selectedAssetClass])

    const [mlReadyFilter, setMlReadyFilter] = useState<string>("")

    const [predefinedDownloads, setPredefinedDownloads] = useState<PredefinedDownloadsResponse>([])

    const { instrumentsApi, downloadsApi} = useQuantdleApi()
    const [expanded, setExpanded] = useState<boolean>(false)
    const containerRef = useRef(null);
    const fileSize = useMemo(() => {
        const asset = predefinedDownloads.find((item) => item.assetClass === selectedAssetClass && item.year.toString() === selectedYear.toString())
        return asset?.fileSize ?? 0
    }, [selectedAssetClass, selectedYear])

    useEffect(() => {
        // get predefined downloads
        setIsLoading(true)

        if(!downloadsApi) return

        downloadsApi.predefinedDownloadsListGet().then((predefinedDownloads) => {
            setPredefinedDownloads(predefinedDownloads.data)
        }, (err) => {
            console.log(err)
        }).finally(() => setIsLoading(false))

        instrumentsApi.getInstruments().then((instruments) => {
            setInstruments(instruments.data)
        }, (err) => {
            console.log(err)
        })

    }, [downloadsApi, instrumentsApi])

    const yearDownloads = group_by_year(predefinedDownloads)

    const handleOnClick = (assetClass: string, year: string) => {

        setSelectedAssetClass(assetClass)
        setSelectedYear(Number(year))
        setShowModal(true)

        if(props.onClick){
            props.onClick(assetClass, year)
        }
    }

    const handleOnDownload = (assetClass: string, year: number, fileSizeBytes?: number) => {
        setShowModal(false)
        const id = toast.loading(`Downloading ${assetClass} ${year}...`)
        // Download the file
        downloadsApi.predefinedDownloadsGetGet(assetClass, year.toString()).then((res) => {
            // fecth the file given the url passed
            // create a link element
            const link = document.createElement('a');
            // set the link element href to the blob url
            link.href = res.data?.downloadUrl ?? "";
            
            // set the link element download attribute to the filename
            link.setAttribute('download', year.toString() === '-1' ? assetClass + '.zip' : assetClass + '_' + year + '.zip');
            
            // simulate a click on the link element
            link.click();
            link.remove();

            // delete the toast
            toast.dismiss(id)
            toast.success(assetClass + " " + year + " download started")
            // update the usage
            dispatch(updateUsage({
                bandwith: Number(fileSizeBytes ?? fileSize) + Number(usage.bandwith),
            }))
            
        },
        error => {
            // delete the toast
            toast.dismiss(id)
            toast.error("Failed to download file: " + error)
        })
    }

    if (isLoading){
        return <Grid xs={12} justify='center' alignItems='center' style={{height: 500}}><QuantdleLoader /></Grid>
    }

    return (
        <>
            {/* MT5 ready downloads */}
            <Grid style={{background: 'var(--nextui-colors-backgroundContrast)', borderRadius: 24, marginLeft: "10%", marginRight: "10%", marginBottom: 20}}>
                {
                    yearDownloads['-1'] ? <OneClickDownloadRowComponent onClick={handleOnClick} title="All" year="-1" row={yearDownloads['-1']}></OneClickDownloadRowComponent> : null
                }
            </Grid>
            <Grid ref={containerRef} className={expanded ? styles.expanded + ' ' + styles.expandable : styles.expandable }>
                {
                Object.keys(yearDownloads).filter((value) => value != '-1').sort((a, b) => Number(b) - Number(a)).map((value) => {
                        return (
                            <OneClickDownloadRowComponent onClick={handleOnClick} title={value} year={value} row={yearDownloads[value]}/>
                        )
                    })
                }
                
            </Grid>
            <Grid style={{display: "flex", justifyContent: 'center', marginTop: 15}}>
                <Button onPress={() => {setExpanded(!expanded); containerRef.current.scrollTo({top: 0, behavior: 'smooth'})}} auto>{expanded ? "View less" : "View more"}</Button>
            </Grid>
            <OneClickModalComponent assetClass={selectedAssetClass} visible={showModal} year={selectedYear} instruments={downloadInstruments} fileSize={fileSize} onClose={() => setShowModal(false)} onDownload={handleOnDownload}/>
            {/* Machine Learning Ready downloads */}
            <Text style={{display: 'flex', alignItems: 'center'}} h3>ML-Ready Downloads<InfoComponent placement="rightEnd" tooltip="Download Machine Learning (ML) ready quality data, large csv files taylored to be used in ML or Data Mining applications."/></Text>
            <Text color="grey" size={'medium'} style={{marginBottom: 20}}>Download historical full-range minutely precision (up to {new Date().getFullYear() - 2003} years) CSV datasets ready to use in Machine Learning applications. You can also upload those files into other tools like Strategy Builders.</Text>
            <Grid xs={12} style={{display: 'flex', paddingLeft: '5%', justifyContent:'flex-start', marginBottom: 20}}>
                    <Input
                        type="text"
                        width="300px"
                        shadow={false}
                        animated={false}
                        css={{ $$inputColor: "var(--nextui-colors-inputColor)" }}
                        placeholder="Search asset..."
                        aria-label="search asset"
                        contentRight={<BsSearch fill="var(--nextui-colors-primary)" />}
                        style={{}}
                        clearable
                        onChange={(e) => {
                        if(e.type === 'click'){
                            // this is the clear commandç
                            setMlReadyFilter("")
                        }else{
                            e.preventDefault();
                            setMlReadyFilter(e.target.value)
                        }
                        }}
                    />
            </Grid>
            <Grid style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', paddingLeft: '5%', paddingRight: '5%', minHeight: 340}}>
                {
                    predefinedDownloads.filter((item) => item.assetClass.includes('ML')).filter((item) => item.assetClass.toLowerCase().includes(mlReadyFilter.toLowerCase())).map((item) => {
                        return <MLSymbolComponent onDownload={(asset, fileSize) => handleOnDownload(asset, -1, fileSize)} asset={item.assetClass} fileSize={item.fileSize}/>
                    })
                }
            </Grid>
        </>
    )
}

    
