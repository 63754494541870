
import {  Badge, Text, Tooltip } from "@nextui-org/react"
import styles from "./chip.module.css"


export type Colors = "default" | "primary" | "secondary" | "success" | "warning" | "error" 

export const Chip = (props: { label: string, value: string, color?: Colors, onClick?: (label: string, value: string) => void}) => {

    // const [chipList, setChipList] = useState<string[]>([])
    const handleOnClik = () => {
        if(props.onClick){
            props.onClick(props.label, props.value)
        }
    }


    return (

        <div className={styles.chip}>
            <Tooltip
                color={props.color ?? 'secondary'}
                content={<Text transform="capitalize">{props.value.replace(/[,|_\-]/g, " ")}</Text>}
            >
                <Badge variant="bordered" onClick={handleOnClik} style={{userSelect: "none"}}  color={props.color ?? 'secondary'}>{props.label}</Badge>
            </Tooltip>
        </div>
    )
}
