import { Tooltip } from '@nextui-org/react'
import {TbInfoCircle} from 'react-icons/tb'
import styles from './info.module.css'

type Placement = "bottom" | "left" | "right" | "top" | "topStart" | "topEnd" | "leftStart" | "leftEnd" | "bottomStart" | "bottomEnd" | "rightStart" | "rightEnd"

export const InfoComponent = (props: {tooltip: string, placement?: Placement}) => {

    return (
        <Tooltip content={props.tooltip} style={{marginLeft: 5, marginRight: 5}} placement={props.placement ?? 'top'}>
            <TbInfoCircle className={styles.info} color='var(--nextui-colors-accents4)' style={{width: '20px', height: '20px', margin:"5px 0 3px"}}/>
        </Tooltip>
    )

}