import { NextUIProvider } from '@nextui-org/react';
import { BrowserRouter as Router, Routes, Route,  } from 'react-router-dom';
import './App.css';
import 'react-day-picker/dist/style.css';
import './components/date-range-picker/date.css';
import './components/styles/react-tabs.css'; // custom css for react tabs
import { Provider } from 'react-redux'
import { store } from './shared/redux/store';
import { quantdle, quantdleLigth, quantdleTheme } from './theme/theme';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationsPage } from './pages/notification.page';
import { TransfersPage } from './pages/transfers.page';
import { TransferStatusComponent } from './components/trasfer-status/transfer-status.component';
import { HomePage } from './pages/home.page';
import { AdminLayout } from './layouts/admin.layout';
import { Amplify } from 'aws-amplify';
import { Authenticator, Theme, ThemeProvider, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import ParticleBackground from './components/particles/particles-background';
import { QuantdleLogoAnimation } from './components/quantlde-logo-animation/quantdle-logo-animation.component';
import { Text } from '@nextui-org/react'
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { Toaster } from 'react-hot-toast';

// configure Amplify
Amplify.configure({
  Auth: {
    Cognito: {
      
      userPoolId: process.env.REACT_APP_QUANTDLE_STAGE==='prod' ? 'eu-west-3_LN5Ik3mKg' :'eu-west-3_TTZrPZP4p',
      userPoolClientId: process.env.REACT_APP_QUANTDLE_STAGE==='prod' ? 'celqbqf610h6hegu3ffs3tb7e' : '7j6a30vd0nu61s9gopltkfcelg',
      signUpVerificationMethod: 'code', // to send verification link instead of code,
      loginWith: {
        email: true
      }
    }
    
  }
})

const amplifyTheme: Theme = {
    name: "quantdle",
    tokens: {
      borderWidths: {
        small: {
          value: "0px",
        },
      },
      radii: {
        small: {
          value: quantdleTheme.theme.radii.md,
        },
        medium: {
          value: quantdleTheme.theme.radii.md,
        },
        large: {
          value: quantdleTheme.theme.radii.md,
        },
        xl: {
          value: quantdleTheme.theme.radii.md,
        },
        xxl: {
          value: quantdleTheme.theme.radii.md,
        },
        xs: {
          value: quantdleTheme.theme.radii.md,
        },
        xxxl: {
          value: quantdleTheme.theme.radii.md,
        },
      },
      colors: {
        
        border: {
          primary: quantdleTheme.theme.colors.primary,
          secondary: quantdleTheme.theme.colors.accents1,
        },
        background: {
          primary: quantdleTheme.theme.colors.backgroundContrast,
          secondary: quantdleTheme.theme.colors.backgroundAlpha,
          quaternary: quantdleTheme.theme.colors.backgroundAlpha,
          tertiary: quantdleTheme.theme.colors.backgroundAlpha,
        },
        font: {
          primary: "#fff",
          secondary: "#fff",
        },
        primary: {
          "10": quantdleTheme.theme.colors.primary,
          "100": quantdleTheme.theme.colors.primary,
          "20": quantdleTheme.theme.colors.primary,
          "40": quantdleTheme.theme.colors.primary,
          "60": quantdleTheme.theme.colors.primary,
          "80": quantdleTheme.theme.colors.primary,
          "90": quantdleTheme.theme.colors.primary,
        }
        
      },
      fonts: {
        default: {
          static: {
            value: quantdleTheme.theme.fonts.sans,
          }
        }
      },
      components: {
        button: {
          backgroundColor: {
            value: quantdleTheme.theme.colors.primary,
          },
          borderRadius: quantdleTheme.theme.radii.md,
          _hover: {
            backgroundColor: {
              value: "transparent",
            },
          }
        },
        input: {
          color: {
            value: "#fff",
          },
        },
        alert: {
          error: {
            backgroundColor: {
              value: "#300313",
            },
            color: {
              value: "#F31260",
            }
          }
          
        }
      }
    }
}

export default function App() {

  return (
    <Provider store={store}>
      <Toaster position='bottom-right' toastOptions={{
                  position: 'bottom-right',
                  style: {
                    borderRadius: '12px',
                    background: '#202028',
                    color: '#fff',
                    maxWidth: '400px',
                    zIndex: 10000
                  }
            }}/>
      <ParticleBackground />
      <NotificationComponent />  {/* notification component to receive notifications from websocket api */}
      <TransferStatusComponent /> {/* component to receive transfer status updates from websocket api */}
      <NextThemesProvider
          defaultTheme="dark"
          attribute="class"
          value={{
            light: quantdleLigth.className,
            dark: quantdle.className
          }}
      >
        <NextUIProvider >
          <ThemeProvider theme={amplifyTheme} >
            <Router>
                <Authenticator hideSignUp 
                
                components={{
                  Header: () => (
                    <View style={{marginBottom: 10}}>
                      <QuantdleLogoAnimation width={300} type='static' />
                    </View>
                    ),
                    Footer: () => (
                      <View style={{ display: 'flex', justifyContent: 'center', marginTop: 10}}>
                        <Text small color='grey'>© {new Date().getFullYear()} Quantsoft Technologies. All rights reserved.</Text>
                      </View>
                    )
                }}>
                  {
                    ({ signOut, user }) => (
                      
                        <AdminLayout>
                          <Routes>
                              <Route path="/" element={<HomePage />} />
                              <Route path="/notifications" element={<NotificationsPage />} />
                              <Route path="/transfers" element={<TransfersPage />} />
                          </Routes>
                        </AdminLayout>              
                    )
                  }
                </Authenticator>
              </Router>
            </ThemeProvider>
          </NextUIProvider>
        </NextThemesProvider>
    </Provider>
  );
}
