import { Grid, Text, useTheme } from "@nextui-org/react";
import { PredefinedDownloadsResponse } from "../../shared/download-api/models";
import { OneClickDownloadButtonComponent } from "./one-click-download-button.component";
import styles from './one-click-download-row.module.css'


export const OneClickDownloadRowComponent = (props: {row: PredefinedDownloadsResponse, title: string, year: string, onClick?: (assetClass: string, year: string) => void}) => {

    const rowElements = ["ALL", "FOREX", "MAJORS", "CROSSES", "COMMODITIES","ENERGY", "PRECIOUS_METALS", "EQUITIES", "INDICES"]
    const {isDark} = useTheme();

    const getColor = (assetClass: string) => {
        switch(assetClass){
            case "FOREX":
                return "primary"
            case "COMMODITIES":
                return "success"
            case "EQUITIES":
                return "warning"
            case "INDICES":
                return "warning"
            case "ENERGY":
                return "success"
            case "PRECIOUS_METALS":
                return "success"
            case "MAJORS":
                return "primary"
            case "CROSSES":
                return "primary"
            default:
                return "default"
        }
    }

    const handleOnClick = (assetClass: string, year: string) => {
        if(props.onClick){
            props.onClick(assetClass, year)
        }
    }

    return (
        <Grid css={{display: 'flex', alignContent: 'center', justifyContent: 'center', flexDirection: 'column'}}>
            <Grid css={{p: "$6", justifyContent: 'center', display: 'flex', flexWrap: 'wrap'}}>
                <Text h3 css={{minWidth: 100, alignSelf: 'center', display: 'flex'}}>{props.title}</Text>
                <Grid css={{display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center'}}>
                    {
                        rowElements.map((assetClass) => {

                            const asset = props.row.find((item) => item.assetClass === assetClass)
                            const disabled = asset === undefined

                            return (
                                <div style={{margin: 10, display: 'flex', flexWrap: 'wrap'}} className={assetClass == 'ALL' ? isDark ? styles.all : styles.allLigth : ''}>
                                    <OneClickDownloadButtonComponent onClick={handleOnClick} disabled={disabled} assetClass={assetClass} year={props.year} colors={getColor(assetClass)} fileSize={asset?.fileSize ?? 0} />
                                </div>
                            )
                        })
                    }
                </Grid>
            </Grid>
        </Grid>
    )

}