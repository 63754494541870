import { JobStatusUpdate } from "../models/quantdle-job-status-update"
import { WebsocketApiMessage } from "../websocket-api/api"


// create a function the will map the data from the api to the data we need, the datra from the api is in snake case and we need to convert it to camel case
export const fromServerFormatToQuantdleJobStatus = (message: WebsocketApiMessage): JobStatusUpdate => {
    return {
        jobId: message.jobId,
        status: message.status,
        fileSize: message.fileSize,
        dataFrom: message.dataFrom,
        dataTo: message.dataUntil,
        createdOn: message.createdOn,
        taskStatusUpdates: message.taskStatuses?.map((taskStatusUpdate: any) => {
            return {
                symbol: taskStatusUpdate.symbol,
                status: taskStatusUpdate.status
            }
        })
    }
}