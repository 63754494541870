
import { Button, Grid, Link, Navbar } from "@nextui-org/react"
import { useNavigate } from "react-router-dom"
import styles from '../styles/header.module.css'
import { QuantdleLogoAnimation } from "../quantlde-logo-animation/quantdle-logo-animation.component"
import { useAuthenticator } from "@aws-amplify/ui-react"
import { useAppSelector } from "../../shared/hooks/redux.hook"
import { PlanModalComponent } from "../plan-modal/plan-modal.component"
import { useState } from "react"
import { SwitchThemeComponent } from "../switch-theme/switch-theme.component"
import { EAModal } from "../ea_modal/ea-modal.component"

export const HeaderRedesignComponent = () => {

    const { authStatus } = useAuthenticator((context) => [context.authStatus]);
    const { signOut } = useAuthenticator((context) => [context.signOut]);

    const usage = useAppSelector(state => state.usage)
    const [showEAModal, setShowEAModal] = useState<boolean>(false)
    const navigate = useNavigate()
    const isLogged = authStatus === 'authenticated'


    let sidenavWidth = "0px";
    if(isLogged){
        sidenavWidth = "100px"
    }else{
        sidenavWidth = "0px"
    }

    const handleOnMt5EaClick = () => {

        setShowEAModal(true)

    }

    return (
        <Navbar disableBlur maxWidth="fluid" variant="sticky" style={{marginLeft:`calc(100px" - ${  sidenavWidth  })`}} css={{
            $$navbarBackgroundColor: "var(--nextui-colors-backgroundContrast)",
            $$navbarBlurBackgroundColor: "var(--nextui-colors-backgroundContrast)"
            }}>
            <Navbar.Brand style={{display: 'flex', width: "100%", justifyContent: "space-between"}}>
                <QuantdleLogoAnimation type="static"/>
                <Grid xs={2} sm={0}>
                    <SwitchThemeComponent />
                </Grid>
                <Navbar.Toggle aria-label="toggle navigation" showIn={'sm'}/>
            </Navbar.Brand>
            {/* Wrap nav content in grid to delete menu on mobile */}
            <Grid xs={0} md={2}>
                <Navbar.Content>
                    <SwitchThemeComponent />
                    <Button bordered light auto onPress={handleOnMt5EaClick} className={styles.button}>
                        Download Auto-MT5 EA
                    </Button>
                </Navbar.Content>
            </Grid>
            <Navbar.Collapse>
                <Navbar.CollapseItem key={1}>
                    <Link href="/" color={"text"}>Home</Link>
                </Navbar.CollapseItem>
                <Navbar.CollapseItem key={2}>
                    <Link  onPress={handleOnMt5EaClick} color={"text"}>Download Auto MT5 EA</Link>
                </Navbar.CollapseItem>
                <Navbar.CollapseItem key={3}>
                    <Link href="/transfers" color={"text"}>Transfers</Link>
                </Navbar.CollapseItem>
                <Navbar.CollapseItem key={4}>
                    <Link href="/notifications" color={"text"}>Notifications</Link>
                </Navbar.CollapseItem>
                <Navbar.CollapseItem key={5}>
                    <Link href="https://quantdle.com/faq.html" target="_blank" color={"text"}>FAQ</Link>
                </Navbar.CollapseItem>
                <Navbar.CollapseItem key={6}>
                    <Link onPress={signOut} color={"error"}>Logout</Link>
                </Navbar.CollapseItem>
            </Navbar.Collapse>
            <EAModal visible={showEAModal} onClose={() => setShowEAModal(false)}/>
        </Navbar>
    )

}
