import { TbCloudDownload, TbTrash} from 'react-icons/tb'
import { useAppSelector } from '../../shared/hooks/redux.hook'
import { IconButton } from '../icon-button/icon-button.component'
import { JobStatusEnum } from '../../shared/models/quantdle-job-status-update'
import { Tooltip } from '@nextui-org/react'




export const JobActionsComponent = (props: {jobId: string, onDownload?: (jobId: string) => void, onDelete?: (jobId: string) => void}) =>{

    const jobStatus = useAppSelector(state => state.jobStatus.find((job) => job.jobId === props.jobId))

    const handleOnDownload = () => {
        if(props.onDownload) {
            props.onDownload(props.jobId)
        }
    }

    const handleOnDelete = () => {
        if(props.onDelete) {
            props.onDelete(props.jobId)
        }
    }

    if(!jobStatus) {
        return <></>
    }

    return (
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
            <Tooltip content="Download" color={jobStatus.status !== JobStatusEnum.COMPLETED ? 'default': 'primary'}>
                <IconButton disabled={jobStatus.status !== JobStatusEnum.COMPLETED} onClick={handleOnDownload} style={{marginRight: 5}}>
                    <TbCloudDownload  color={jobStatus.status === JobStatusEnum.COMPLETED ? 'var(--nextui-colors-primary)' : "var(--nextui-colors-gray400)"} style={{width: '30px', height: '30px', margin:"5px 0"}}/>
                </IconButton>
            </Tooltip>
            <Tooltip content="Delete" color="error">
                <IconButton disabled={jobStatus.status !== JobStatusEnum.COMPLETED} onClick={handleOnDelete}>
                    <TbTrash color='var(--nextui-colors-error)' style={{width: '30px', height: '30px', margin:"5px 0"}}/>
                </IconButton>
            </Tooltip>
        </div>
    )
}