import { Badge, Grid, Text} from "@nextui-org/react"
import  styles  from './ml-symbol.module.css';
import { Image } from "@nextui-org/react";
import ML from '../../assets/machine-learning.svg'
import { formatFileSize } from "../../shared/utils/utils";
import { MLSymbolModalComponent } from "./ml-symbol-modal.component";
import { useState } from "react";


export const MLSymbolComponent = (props: {asset: string, onDownload?: (asset: string, fileSize?: number) => void, fileSize?: number, hide?: boolean}) => {

    const [showModal, setShowModal] = useState<boolean>(false)
    const instrumentName = props.asset.split("_")[1]

    const handleOnDownload = () => {
        if(props.onDownload){
            props.onDownload(props.asset, props.fileSize)
        }
    }

    return(
        <div className={props.hide ? styles['scale-out-center'] : styles['scale-in-center']} onClick={() => setShowModal(true)}>
            <Badge content={formatFileSize(props.fileSize, 0)} style={{transform: "scale(1) translate(27%, -7%)"}} color={'secondary'} variant={'flat'} placement={'top-right'} size={'xs'} disableOutline>
                <Grid style={{minWidth: 100, padding: 10, borderRadius: '7px', margin: 10, display:"flex", alignContent: "center", backgroundColor:"var(--nextui-colors-backgroundContrast)", justifyContent: 'space-between'}}  className={styles.asset}>
                    <Grid xs={8} style={{display: 'flex' , flexDirection: 'row', cursor: 'pointer'}} >
                        <Text h4 style={{margin: 0, userSelect: "none"}}>{instrumentName}</Text>
                    </Grid>
                </Grid>
            </Badge>
            <MLSymbolModalComponent onDownload={handleOnDownload} downloadName={props.asset} instrument={instrumentName} fileSize={props.fileSize} visible={showModal} onClose={() => setShowModal(false)}/>
        </div>
    )

}
