import { Image, useTheme } from '@nextui-org/react';
import styles from './quantdle-logo.module.css';
import logo from "../../assets/quantdle-text.png"
import staticLogo from "../../assets/quantdle.png"
import staticLigthLogo from "../../assets/quantdle-ligth.png"
import { useNavigate } from 'react-router-dom';


export const QuantdleLogoAnimation = (props: {type:string, width?: number, height?: number}) => {

    const navigate = useNavigate()
    const {isDark} = useTheme()

    if(props.type == 'static'){

        return (
            <div onClick={() => navigate('/')} style={{display:"flex", justifyContent:"center", alignItems:"center", cursor: 'pointer'}}>
                <Image src={isDark ? staticLogo : staticLigthLogo}  width={props.width ?? 175} height={props.height ?? 80} style={{marginLeft:"0px"}} className={styles.logo}/>
            </div>
        )
    }


    return(

    <div onClick={() => navigate('/')} style={{display:"flex", justifyContent:"center", alignItems:"center", cursor: 'pointer'}}>
        <div className={styles.squareContainer} style={{marginRight:"25px"}}>
            <div className={`${styles.sectionOne} ${styles.animationDynamicOne}`}></div>
            <div className={`${styles.sectionTwo} ${styles.animationDynamicTwo}`}></div>
            <div className={`${styles.sectionThree} ${styles.animationDynamicThree}`}></div>
        </div>
        <Image src={logo}  width={150} height={80} style={{marginLeft:"0px"}} className={styles.logo}/>
    </div>
    )
}
