/* tslint:disable */
/* eslint-disable */
/**
 * Quandle Backend OpenAPI 3.0
 * This is the Quantdle Backeed that allows you to download historic data
 *
 * OpenAPI spec version: 0.0.1
 * Contact: support@quantdle.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CandleResponse } from '../models';
/**
 * CandlesApi - axios parameter creator
 * @export
 */
export const CandlesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all instruments on the system and its metadata
         * @summary Returns all instruments on the system
         * @param {string} instrumentName The asset type
         * @param {string} [fromDate] The date from wich to retrieve candles (in minute format)
         * @param {string} [toDate] The date to wich to retrieve candles (in minute format)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandles: async (instrumentName: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instrumentName' is not null or undefined
            if (instrumentName === null || instrumentName === undefined) {
                throw new RequiredError('instrumentName','Required parameter instrumentName was null or undefined when calling getCandles.');
            }
            const localVarPath = `/candles/{instrumentName}`
                .replace(`{${"instrumentName"}}`, encodeURIComponent(String(instrumentName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CandlesApi - functional programming interface
 * @export
 */
export const CandlesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Returns all instruments on the system and its metadata
         * @summary Returns all instruments on the system
         * @param {string} instrumentName The asset type
         * @param {string} [fromDate] The date from wich to retrieve candles (in minute format)
         * @param {string} [toDate] The date to wich to retrieve candles (in minute format)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCandles(instrumentName: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CandleResponse>>> {
            const localVarAxiosArgs = await CandlesApiAxiosParamCreator(configuration).getCandles(instrumentName, fromDate, toDate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CandlesApi - factory interface
 * @export
 */
export const CandlesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Returns all instruments on the system and its metadata
         * @summary Returns all instruments on the system
         * @param {string} instrumentName The asset type
         * @param {string} [fromDate] The date from wich to retrieve candles (in minute format)
         * @param {string} [toDate] The date to wich to retrieve candles (in minute format)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCandles(instrumentName: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<CandleResponse>> {
            return CandlesApiFp(configuration).getCandles(instrumentName, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CandlesApi - object-oriented interface
 * @export
 * @class CandlesApi
 * @extends {BaseAPI}
 */
export class CandlesApi extends BaseAPI {
    /**
     * Returns all instruments on the system and its metadata
     * @summary Returns all instruments on the system
     * @param {string} instrumentName The asset type
     * @param {string} [fromDate] The date from wich to retrieve candles (in minute format)
     * @param {string} [toDate] The date to wich to retrieve candles (in minute format)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandlesApi
     */
    public async getCandles(instrumentName: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<CandleResponse>> {
        return CandlesApiFp(this.configuration).getCandles(instrumentName, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }
}
