import { Configuration } from "../api";


export const baseConfig: Configuration = {
  basePath: "https://8wn2fjb3xj.execute-api.eu-south-2.amazonaws.com/staging/".replace(/\/+$/, "")
}


export interface QuantdleApiConfig {
  egressBasePath: string
  paymentBasePath: string
  websocketBasePath: string
  downloadsBasePath: string
}


export function getEnvironment(): QuantdleApiConfig {

  switch (process.env.REACT_APP_QUANTDLE_STAGE) {
    case "prod":
      return {
        egressBasePath: "https://api.quantdle.com",
        paymentBasePath: "https://9az7sg0qi8.execute-api.eu-south-2.amazonaws.com/staging",
        websocketBasePath: "wss://x83bzufiw7.execute-api.eu-west-3.amazonaws.com/staging",
        downloadsBasePath: "https://dyf0xq33z2.execute-api.eu-south-2.amazonaws.com/staging",
      }

    case "dev":
      return {
        egressBasePath: "https://api-dev.quantdle.com",
        paymentBasePath: "https://nsepfubqll.execute-api.eu-south-2.amazonaws.com/staging",
        websocketBasePath: "wss://ea3n5o1j2c.execute-api.eu-west-3.amazonaws.com/staging",
        downloadsBasePath: "https://90jqh1hai4.execute-api.eu-south-2.amazonaws.com/staging",
      }

    default:
      // on not matching case return dev
      return {
        egressBasePath: "https://api-dev.quantdle.com",
        paymentBasePath: "https://nsepfubqll.execute-api.eu-south-2.amazonaws.com/staging",
        websocketBasePath: "wss://ea3n5o1j2c.execute-api.eu-west-3.amazonaws.com/staging",
        downloadsBasePath: "https://90jqh1hai4.execute-api.eu-south-2.amazonaws.com/staging",
      }
  }


}


