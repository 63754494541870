
import styles from './loader.module.css';

export const QuantdleLoader = () =>{
  return (
    <div>
      <div className={styles.squareContainer} style={{marginRight:"25px"}}>
        <div className={`${styles.sectionOne} ${styles.animationDynamicOne}`}></div>
        <div className={`${styles.sectionTwo} ${styles.animationDynamicTwo}`}></div>
        <div className={`${styles.sectionThree} ${styles.animationDynamicThree}`}></div>
      </div>
    </div>
  )
}
