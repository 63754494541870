import { Colors } from '../../components/symbols/chip'


export interface AssetType {
  label: string,
  value: string,
  color: Colors
}


export const assetTypes: AssetType[] = [
  {
    label: "FX",
    value: "forex",
    color: "secondary"
  },
  {
    label: "EQ",
    value: "equities",
    color: "warning"
  },
  {
    label: "CO",
    value: "commodities",
    color: "error"
  }
] 