import { Card, Grid, Text, Button } from "@nextui-org/react"
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css';
import { useAppDispatch, useAppSelector } from "../shared/hooks/redux.hook";
import { useEffect, useState } from "react";
import { formatFileSize } from "../shared/utils/utils";
import { SymbolsPage } from "./symbols.page";
import { InfoComponent } from "../components/info/info.component";
import { PlanModalComponent } from "../components/plan-modal/plan-modal.component";
import { OneClickDownloadComponent } from "../components/one-click-download/one-click-download.component";
import { updateUsage } from "../shared/redux/usage-slice";
import { useQuantdleApi } from "../shared/hooks/quantdle-api.hook";
import { QuantdleLoader } from "../components/quantdle-loader/loader.component";

export interface UserInfo {
  dataConsumption: number
  maxAllowedDataConsumption: number
  dataComsumptionPercentage: number
  customDownloads: number
  maxAllowedCustomDownloads: number
  customDownloadsPercentage: number
  plan: string
}

export const HomePage = () => {


  const dispatch = useAppDispatch()
  const usage = useAppSelector(state => state.usage)

  const { usersApi } = useQuantdleApi()
  const [user, setUser] = useState<UserInfo>({
    dataConsumption: 0,
    maxAllowedDataConsumption: 0,
    dataComsumptionPercentage: 0,
    customDownloads: 0,
    maxAllowedCustomDownloads: 0,
    customDownloadsPercentage: 0,
    plan: 'free'
  })
  const [showPlanModal, setShowPlanModal] = useState<boolean>(false)


  useEffect(() => {
    // update the redux use ussage
    dispatch(updateUsage({
      bandwith: user.dataConsumption,
      maxBandwith: user.maxAllowedDataConsumption,
      customDownloads: user.customDownloads,
      maxCustomDownloads: user.maxAllowedCustomDownloads,
      plan: user.plan
    }))

  }, [user])

  useEffect(() => {

    // check that user api exists
    if(!usersApi) return

    // call the api to get the user info
    usersApi.getUserInfo().then((user) => {

      setUser({
        dataConsumption: user.data.dataConsumption ?? 0,
        maxAllowedDataConsumption: user.data.maxAllowedDataConsumption ?? 0,
        maxAllowedCustomDownloads: user.data.maxAllowedCustomDownloads ?? 0,
        customDownloads: user.data.numberOfCustomDownloads ?? 0,
        dataComsumptionPercentage: Math.ceil(user.data.dataConsumption / user.data.maxAllowedDataConsumption * 100) ?? 0,
        customDownloadsPercentage: Math.ceil(user.data.numberOfCustomDownloads / user.data.maxAllowedCustomDownloads * 100) ?? 0,
        plan: user.data.plan ?? 'free'
      })
      
    })
  }, [usersApi])

  // is userApi is not defined (the token has not been yet retrieved), show the loader
  if(!usersApi) return (<QuantdleLoader />)
  




  return (
    <Grid style={{marginLeft: 20}}>
      <Text h3>Statistics</Text>
      <Grid style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 5, flexWrap: 'wrap'}}>
        <Card variant="bordered" css={{ p: "$6", mw: "400px", margin: 20, borderColor: 'var(--nextui-colors-secondary)'}}>
          <Card.Body css={{ py: "$2" }}>
            <Grid css={{ display: 'flex'}}>
              {/* <LuGauge color="var(--nextui-colors-secondaryLightContrast)" style={{width: '75px', height: '50px', borderRadius: 14, background: 'var(--nextui-colors-secondaryLight)'}}/> */}
              <Grid xs={12} style={{display: 'flex', flexDirection: 'column', marginLeft: 10}}>
                  <Text color="primary" h3 css={{ lineHeight: "$xs", margin: 0 }}>
                      {formatFileSize(usage.bandwith, 0)} <Text small css={{ color: "$accents8" }}>/ {formatFileSize(usage.maxBandwith, 0)}</Text>
                  </Text>
                  <Text b>Bandwidth usage</Text>
              </Grid>
              <Grid>
                <CircularProgressbar styles={{root: {
                  width: 50,
                },
                path: {
                  stroke: 'var(--nextui-colors-secondaryLightContrast)',
                },
                trail: {
                  stroke: 'var(--nextui-colors-secondaryLight)',
                },
                text: {
                  fill: 'var(--nextui-colors-secondaryLightContrast)',
                }
                
                }} value={usage.bandwidthPercentage} text={`${usage.bandwidthPercentage}%`} />
              </Grid>
            </Grid>
          </Card.Body>
        </Card>
        <Card variant="bordered" css={{ p: "$6", mw: "400px", margin: 20, borderColor: 'var(--nextui-colors-warning)'}}>
          <Card.Body css={{ py: "$2" }}>
            <Grid css={{ display: 'flex'}}>
              {/* <LuGauge color="var(--nextui-colors-secondaryLightContrast)" style={{width: '75px', height: '50px', borderRadius: 14, background: 'var(--nextui-colors-secondaryLight)'}}/> */}
              <Grid xs={12} style={{display: 'flex', flexDirection: 'column', marginLeft: 10}}>
                  <Text color="warning" h3 css={{ lineHeight: "$xs", margin: 0 }}>
                      {usage.customDownloads} cd <Text small css={{ color: "$accents8" }}> / {usage.maxCustomDownloads} cd</Text>
                  </Text>
                  <Text b>Custom downloads</Text>
              </Grid>
              <Grid>
                <CircularProgressbar styles={{root: {
                  width: 50,
                  },
                  path: {
                    stroke: 'var(--nextui-colors-warningLightContrast)',
                  },
                  trail: {
                    stroke: 'var(--nextui-colors-warningLight)',
                  },
                  text: {
                    fill: 'var(--nextui-colors-warningLightContrast)',
                  }
                
                }} value={usage.maxCustomDownloads === 0 ? 100 : usage.customDownloadsPercentage} text={`${usage.maxCustomDownloads === 0 ? 100 : usage.customDownloadsPercentage}%`} />
              </Grid>
            </Grid>
          </Card.Body>
        </Card>
        <Card variant="bordered" css={{ p: "$6", mw: "400px", margin: 20, borderColor: 'var(--nextui-colors-success)'}}>
          <Card.Body css={{ py: "$2" }}>
            <Grid css={{ display: 'flex'}}>
              {/* <LuGauge color="var(--nextui-colors-secondaryLightContrast)" style={{width: '75px', height: '50px', borderRadius: 14, background: 'var(--nextui-colors-secondaryLight)'}}/> */}
              <Grid xs={12} style={{display: 'flex', flexDirection: 'column', marginLeft: 10}}>
                  <Text transform="capitalize" h3 css={{ lineHeight: "$xs", margin: 0 }}>
                      {
                        // the group is given by {groupNmae}-group, get rid of the -group
                        usage.plan ?? 'Free'
                      }
                  </Text>
                  <Text css={{ color: "$accents8" }}>Account Type</Text>
              </Grid>
              <Grid style={{alignItems: 'center', display: 'flex'}}>
                <Button auto flat color={'success'} onPress={() => setShowPlanModal(true)}>Upgrade</Button>
              </Grid>
            </Grid>
          </Card.Body>
        </Card>
      </Grid>
      <Text h3 style={{display: 'flex', alignItems: 'center'}}>MT5-Ready Downloads</Text>
      <Text color="grey" size={'medium'} style={{marginBottom: 20}}>Download historical data ready to be loaded into your MT5 platform as Custom Symbols using our Data-Loader Expert Advisor.</Text>
            <OneClickDownloadComponent />
      <Text h3>Create custom download</Text>
      <Text color="grey" size={'medium'} style={{marginBottom: 20}}>Create specific files with predefined start and end dates for multiple symbols.</Text>
        <SymbolsPage />
      <PlanModalComponent visible={showPlanModal} onClose={() => setShowPlanModal(false)}/>
    </Grid>
   
  )
}
