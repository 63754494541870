import { useEffect, useMemo } from "react"
import { useAppDispatch, useAppSelector } from "../../shared/hooks/redux.hook"
import { WebsocketApi } from "../../shared/websocket-api/api"
import { addNotification, setNotifications } from "../../shared/redux/notification-slice"
import { fromServerFormatToQuantdleNotification } from "../../shared/mappers/quantdle-notifications.mappers"
import { fromServerFormatToQuantdleJobStatus } from "../../shared/mappers/quantdle-job-notification.mappers"
import { toast } from "react-hot-toast"
import { addJobStatus } from "../../shared/redux/job-status-slice"
import { useQuantdleApi } from "../../shared/hooks/quantdle-api.hook"




export const NotificationComponent = () => {

    const dispatch = useAppDispatch()
    const { websocketApi } = useQuantdleApi()

    useEffect(() => {

        if(!websocketApi) return

        websocketApi.onConnect(() => {
            websocketApi.send({action: "getNotifications"})
        })

        websocketApi.onMessage((notification) => {
            switch (notification.topic) {
                case "getNotifications":
                    dispatch(setNotifications(notification.message.map((item: any) => fromServerFormatToQuantdleNotification(item))))
                    break;
                
                case "newNotification":
                    dispatch(addNotification(notification))
                    toast(notification.message)
                    break;
                case "job-status-updated":
                    // this are notifications for job status (download) updates
                    const newStatus = fromServerFormatToQuantdleJobStatus(notification.message)
                    dispatch(addJobStatus(newStatus))
                    break;
    
                default:
                    break;
            }
        })

        websocketApi.connect()
    
    }, [websocketApi])

    
    return (
        <></>
    )


}
