import {  Badge, Button, Link, Modal, Table, Text, Image } from "@nextui-org/react"
import {  useAppDispatch, useAppSelector } from "../shared/hooks/redux.hook";
import { useMemo, useState } from "react";
import { JobStatusComponent } from "../components/job/job-status.component";
import { JobActionsComponent } from "../components/job/job-actions.component";
import toast from "react-hot-toast";
import { removeJobStatus } from "../shared/redux/job-status-slice";
import { useQuantdleApi } from "../shared/hooks/quantdle-api.hook";
import styles from './transfers.module.css'

export const TransfersPage = () => {

    const jobs = useAppSelector(state => state.jobStatus) 
    const dispatch = useAppDispatch()
    const { downloadsApi } = useQuantdleApi()

    const [selectedJob, setSelectedJob] = useState<string>()
    const [visible, setVisible] = useState(false);
    const modalHandler = (jobId: string) =>{
            setSelectedJob(jobId)
            setVisible(true)
        }
    const closeHandler = () => {
        setVisible(false);
    };

    const onDeleteHandler = () => {
        // delete the job
        setVisible(false);

        // set the toast notification
        const id = toast.loading(`Deleting ${selectedJob}...`)
        downloadsApi.deleteJobIdDelete(selectedJob).then(() => {
            toast.success(`Deleted ${selectedJob}`)
            dispatch(removeJobStatus(selectedJob))
            toast.dismiss(id)
        }).catch((err: any) => {
            toast.error(`Failed to delete ${selectedJob}: ${err}`)
            toast.dismiss(id)
        })

    }

    const onDownloadHandler = (jobId: string) => {
        // download the job
        const selectedJob = jobs.find((job) => job.jobId === jobId)
        const fileName = selectedJob.taskStatusUpdates.length > 1 ? `Quantdle - ${selectedJob.createdOn}.zip`: `${selectedJob.taskStatusUpdates[0].symbol}.zip`
        downloadsApi.downloadGet(jobId).then((res) => {
            // fecth the file given the url passed
            // create a link element
            const link = document.createElement('a');
            // set the link element href to the blob url
            link.href = res.data?.downloadUrl ?? "";
            // set the link element download attribute to the filename
            link.setAttribute('download', fileName);
            // simulate a click on the link element
            link.click();
        })
    }

    const formatFileSize = (bytes: number, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }


    const rows = useMemo(() => {
        
        const rows = jobs.map((job, index) => {

            const createdOn = new Date(job.createdOn)
            // compute the expiresIn in hours to expiration, the download link expires in 48 hours after creation
            const expiresIn = Math.floor((createdOn.getTime() + (48 * 60 * 60 * 1000) - new Date().getTime()) / (60 * 60 * 1000))

            // create a green badge if the expires in is greater than 8 hours, otherwise create a red badge
            const expiresInBadge = expiresIn > 8 ? <Badge variant={'bordered'} color={'success'}>{expiresIn} hours</Badge> : <Badge variant={'bordered'} color={'error'}>{expiresIn} hours</Badge>

            const fileSizeBadge = job.fileSize ? <Badge variant={'flat'}>{formatFileSize(job.fileSize)}</Badge> : "-"

            const assets = job.taskStatusUpdates.map((status) => status.symbol).join(", ")

            return {
                key: index.toString(),
                jobId: job.jobId,
                assets: assets.length > 40 ? <Text>{assets.substring(0, 40)}...</Text> : assets,
                dateRange: <Text>{job.dataFrom} - {job.dataTo}</Text>,
                createdOn: <Text>{new Date(job.createdOn).toDateString()}</Text>,
                expiresIn: expiresInBadge,
                taskStatus: <JobStatusComponent status={job.taskStatusUpdates}/>,
                actions: <JobActionsComponent jobId={job.jobId} onDelete={modalHandler} onDownload={onDownloadHandler}/>,
                fileSize: fileSizeBadge,
                sortkey: createdOn.getTime()
            }    
    
        })
        // sort elements by sort key
        rows.sort((a, b) => {
            return b.sortkey - a.sortkey
        })

        return rows

    }, [jobs]);


    const columns = [
        {
            key: "jobId",
            label: "ID",
        },
        {
            key: "assets",
            label: "Assets",
        },
        {
            key: "dateRange",
            label: "Date range",
        },
        {
            key: "createdOn",
            label: "Created On"
        },
        {
            key: "expiresIn",
            label: "Expires In"
        },
        {
            key: "fileSize",
            label: "Download Size"
        },
        {
            key: "taskStatus",
            label: "Status",
        },
        {
            key: "actions",
            label: "Actions",
        }
        
    ];

    if (jobs.length === 0) {
        
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                <Text h4 b>You have not created a custom download yet</Text>
                <Text>Select a set of assets on the <Link block color={'secondary'}  href="/">assets page</Link>  and download them, then you'll see new entry here</Text>
                <Image className={styles.tutorial} src={'/custom_download.gif'} width={450} height={450} alt={'empty'}/>
            </div>
        )
    }
    

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '15px'}}>
        <div style={{justifyContent: 'flex-start', width: '100%'}}>
            <Text h2>Transfers</Text>
        </div>
        <div style={{width: '100%'}}>
            {/* unread notifications */}
            <Table
                aria-label="Transfer jobs table"
                css={{
                    height: "auto",
                    width: "100%",
                    userSelect: "none",
                }}
                bordered
                color={'secondary'}
                hoverable
            >
            <Table.Header columns={columns}>
                {(column) => (
                    <Table.Column key={column.key}>{column.label}</Table.Column>
                )}
            </Table.Header>
            <Table.Body items={rows}>
                    {(item: any) => (
                    <Table.Row key={item.key}>
                        {(columnKey: any) => {
                                return <Table.Cell>{item[columnKey]}</Table.Cell>
                            }
                        }
                    </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </div>
        {/* Delete Modal */}
        <Modal
            closeButton
            aria-labelledby="modal-title"
            open={visible}
            onClose={closeHandler}
        >
            <Modal.Header>
            <Text id="modal-title" size={18}>
                <Text b size={18}>
                    Delete
                </Text>
            </Text>
            </Modal.Header>
            <Modal.Body>
                <Text>Are you sure you want to delete <Text b>{selectedJob}</Text> tranfer?</Text>
            </Modal.Body>
            <Modal.Footer>
            <Button auto  color="primary" onPress={closeHandler}>
                Nope
            </Button>
            <Button auto ghost color={'error'} onPress={onDeleteHandler}>
                Yes
            </Button>
            </Modal.Footer>
        </Modal>
    </div>
    
    )
}