import { Modal, Text, useModal, Button } from "@nextui-org/react"
import { fetchUserAttributes } from 'aws-amplify/auth'
import { useEffect, useState } from "react";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}


export const PlanModalComponent = (props: {visible: boolean, onClose: () => void, title?: string}) => {


    const [customerId, setCustomerId] = useState<string | undefined>()
    const [email, setEmail] = useState<string | undefined>()

    const pricingTableId = process.env.REACT_APP_QUANTDLE_STAGE==='prod' ? 'prctbl_1NxrVfDIBc4U3IqhzR2CtDlV' : 'prctbl_1NuyxoDIBc4U3IqhWdvlUf5X'
    const publishableKey = process.env.REACT_APP_QUANTDLE_STAGE==='prod' ? 'pk_live_51NTLFmDIBc4U3Iqh7FhMViGdp5bnPaxIdN4hWY6ccYK1shxgXwgKZC0Lol239KXdUQW7vnd702pWzV0D1kvQifIO00aLDJ8jl2' : 'pk_test_51NTLFmDIBc4U3Iqh86GaiUtIfgaR8ufprRsYohXMubDI8AX65chqaVUupSKl6JNTNzVM5iOennKBdkMMSkbaAlfd009eMBZVYy'


    async function handleFetchUserAttributes() {
        try {
            const userAttributes = await fetchUserAttributes();
            setCustomerId(userAttributes?.['custom:stripeCustomerID'] ?? undefined);
            setEmail(userAttributes?.email);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() =>  {
        handleFetchUserAttributes()
    }, [])


    return (
        <Modal
            scroll
            width="1000px"
            // fullScreen
            blur
            closeButton
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={props.visible}
            style={{zIndex: 1000}}
            onClose={props.onClose}
        >
        <Modal.Header>
            <Text id="modal-title" size={18}>
                {props.title ?? 'Upgrade you plan'}
            </Text>
        </Modal.Header>
        <Modal.Body>
            <stripe-pricing-table style={{background: '#16181a !important'}} 
                pricing-table-id={pricingTableId}
                publishable-key={publishableKey}
                client-reference-id={customerId}
                customer-email={email}
                customer-id={customerId}
            >
            </stripe-pricing-table>
        </Modal.Body>
        <Modal.Footer>
            <Button flat auto onPress={() => props.onClose()}>
                Close
            </Button>
        </Modal.Footer>
        </Modal>
    )

}