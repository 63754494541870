/* tslint:disable */
/* eslint-disable */
/**
 * Quandle Backend OpenAPI 3.0
 * This is the Quantdle Backeed that allows you to download historic data
 *
 * OpenAPI spec version: 0.0.1
 * Contact: support@quantdle.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ConfirmForgotPasswordRequest } from '../models';
import { ConfirmSignUpRequest } from '../models';
import { ForGotPasswordRequest } from '../models';
import { GenericResponse } from '../models';
import { LoginRequest } from '../models';
import { LoginResponse } from '../models';
import { RefreshTokenRequest } from '../models';
import { RefreshTokenResponse } from '../models';
import { ResendSignUpCodeRequest } from '../models';
import { RespondToChallengeRequest } from '../models';
import { SignUpRequest } from '../models';
import { SignUpResponse } from '../models';
import { UserInfoResponse } from '../models';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Initiate forgot password flow
         * @summary Initiate forgot password flow
         * @param {ConfirmForgotPasswordRequest} body Email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmForgotPassword: async (body: ConfirmForgotPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling confirmForgotPassword.');
            }
            const localVarPath = `/users/confirm_forgot_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Confirms that the email of the user is valid through a code sent to his/her email
         * @summary Confirms the user
         * @param {ConfirmSignUpRequest} body Email and code of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmSignUp: async (body: ConfirmSignUpRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling confirmSignUp.');
            }
            const localVarPath = `/users/confirm_sign_up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Initiate forgot password flow
         * @summary Initiate forgot password flow
         * @param {ForGotPasswordRequest} body Email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (body: ForGotPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling forgotPassword.');
            }
            const localVarPath = `/users/forgot_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves comsumption info of the user, such as the number of requests made
         * @summary Retrieve info of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Logs in a user and responses with access token and identity token
         * @summary Logs in an user
         * @param {LoginRequest} body User and password for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (body: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling login.');
            }
            const localVarPath = `/users/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Refresh the expired tokens given a refresh token
         * @summary Refresh tokens
         * @param {RefreshTokenRequest} body Refresh token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (body: RefreshTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling refreshToken.');
            }
            const localVarPath = `/users/refresh_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Resend an email to the user containing a new confirmation code
         * @summary Resend confirmation mail to the user
         * @param {ResendSignUpCodeRequest} body Email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendSignUpCode: async (body: ResendSignUpCodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling resendSignUpCode.');
            }
            const localVarPath = `/users/resend_sign_up_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Responds to a challenge initiated by the login method (403 status code)
         * @summary Responds to a challenge
         * @param {RespondToChallengeRequest} body The challenge response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        respondToChallenge: async (body: RespondToChallengeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling respondToChallenge.');
            }
            const localVarPath = `/users/respond_to_challenge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Registers a user in the Quantdle backend with the default role
         * @summary Signs up an user
         * @param {SignUpRequest} body User and password for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUp: async (body: SignUpRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling signUp.');
            }
            const localVarPath = `/users/sign_up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Initiate forgot password flow
         * @summary Initiate forgot password flow
         * @param {ConfirmForgotPasswordRequest} body Email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmForgotPassword(body: ConfirmForgotPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GenericResponse>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).confirmForgotPassword(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Confirms that the email of the user is valid through a code sent to his/her email
         * @summary Confirms the user
         * @param {ConfirmSignUpRequest} body Email and code of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmSignUp(body: ConfirmSignUpRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GenericResponse>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).confirmSignUp(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Initiate forgot password flow
         * @summary Initiate forgot password flow
         * @param {ForGotPasswordRequest} body Email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(body: ForGotPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GenericResponse>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).forgotPassword(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves comsumption info of the user, such as the number of requests made
         * @summary Retrieve info of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserInfoResponse>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).getUserInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Logs in a user and responses with access token and identity token
         * @summary Logs in an user
         * @param {LoginRequest} body User and password for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(body: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<LoginResponse>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).login(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Refresh the expired tokens given a refresh token
         * @summary Refresh tokens
         * @param {RefreshTokenRequest} body Refresh token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(body: RefreshTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<RefreshTokenResponse>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).refreshToken(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Resend an email to the user containing a new confirmation code
         * @summary Resend confirmation mail to the user
         * @param {ResendSignUpCodeRequest} body Email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendSignUpCode(body: ResendSignUpCodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GenericResponse>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).resendSignUpCode(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Responds to a challenge initiated by the login method (403 status code)
         * @summary Responds to a challenge
         * @param {RespondToChallengeRequest} body The challenge response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async respondToChallenge(body: RespondToChallengeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<LoginResponse>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).respondToChallenge(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Registers a user in the Quantdle backend with the default role
         * @summary Signs up an user
         * @param {SignUpRequest} body User and password for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signUp(body: SignUpRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<SignUpResponse>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).signUp(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Initiate forgot password flow
         * @summary Initiate forgot password flow
         * @param {ConfirmForgotPasswordRequest} body Email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmForgotPassword(body: ConfirmForgotPasswordRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> {
            return UsersApiFp(configuration).confirmForgotPassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Confirms that the email of the user is valid through a code sent to his/her email
         * @summary Confirms the user
         * @param {ConfirmSignUpRequest} body Email and code of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmSignUp(body: ConfirmSignUpRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> {
            return UsersApiFp(configuration).confirmSignUp(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Initiate forgot password flow
         * @summary Initiate forgot password flow
         * @param {ForGotPasswordRequest} body Email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(body: ForGotPasswordRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> {
            return UsersApiFp(configuration).forgotPassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves comsumption info of the user, such as the number of requests made
         * @summary Retrieve info of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfo(options?: AxiosRequestConfig): Promise<AxiosResponse<UserInfoResponse>> {
            return UsersApiFp(configuration).getUserInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * Logs in a user and responses with access token and identity token
         * @summary Logs in an user
         * @param {LoginRequest} body User and password for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(body: LoginRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<LoginResponse>> {
            return UsersApiFp(configuration).login(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Refresh the expired tokens given a refresh token
         * @summary Refresh tokens
         * @param {RefreshTokenRequest} body Refresh token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(body: RefreshTokenRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<RefreshTokenResponse>> {
            return UsersApiFp(configuration).refreshToken(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Resend an email to the user containing a new confirmation code
         * @summary Resend confirmation mail to the user
         * @param {ResendSignUpCodeRequest} body Email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendSignUpCode(body: ResendSignUpCodeRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<GenericResponse>> {
            return UsersApiFp(configuration).resendSignUpCode(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Responds to a challenge initiated by the login method (403 status code)
         * @summary Responds to a challenge
         * @param {RespondToChallengeRequest} body The challenge response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async respondToChallenge(body: RespondToChallengeRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<LoginResponse>> {
            return UsersApiFp(configuration).respondToChallenge(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Registers a user in the Quantdle backend with the default role
         * @summary Signs up an user
         * @param {SignUpRequest} body User and password for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signUp(body: SignUpRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<SignUpResponse>> {
            return UsersApiFp(configuration).signUp(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Initiate forgot password flow
     * @summary Initiate forgot password flow
     * @param {ConfirmForgotPasswordRequest} body Email of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public async confirmForgotPassword(body: ConfirmForgotPasswordRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<GenericResponse>> {
        return UsersApiFp(this.configuration).confirmForgotPassword(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Confirms that the email of the user is valid through a code sent to his/her email
     * @summary Confirms the user
     * @param {ConfirmSignUpRequest} body Email and code of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public async confirmSignUp(body: ConfirmSignUpRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<GenericResponse>> {
        return UsersApiFp(this.configuration).confirmSignUp(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Initiate forgot password flow
     * @summary Initiate forgot password flow
     * @param {ForGotPasswordRequest} body Email of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public async forgotPassword(body: ForGotPasswordRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<GenericResponse>> {
        return UsersApiFp(this.configuration).forgotPassword(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Retrieves comsumption info of the user, such as the number of requests made
     * @summary Retrieve info of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public async getUserInfo(options?: AxiosRequestConfig) : Promise<AxiosResponse<UserInfoResponse>> {
        return UsersApiFp(this.configuration).getUserInfo(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Logs in a user and responses with access token and identity token
     * @summary Logs in an user
     * @param {LoginRequest} body User and password for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public async login(body: LoginRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<LoginResponse>> {
        return UsersApiFp(this.configuration).login(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Refresh the expired tokens given a refresh token
     * @summary Refresh tokens
     * @param {RefreshTokenRequest} body Refresh token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public async refreshToken(body: RefreshTokenRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<RefreshTokenResponse>> {
        return UsersApiFp(this.configuration).refreshToken(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Resend an email to the user containing a new confirmation code
     * @summary Resend confirmation mail to the user
     * @param {ResendSignUpCodeRequest} body Email of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public async resendSignUpCode(body: ResendSignUpCodeRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<GenericResponse>> {
        return UsersApiFp(this.configuration).resendSignUpCode(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Responds to a challenge initiated by the login method (403 status code)
     * @summary Responds to a challenge
     * @param {RespondToChallengeRequest} body The challenge response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public async respondToChallenge(body: RespondToChallengeRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<LoginResponse>> {
        return UsersApiFp(this.configuration).respondToChallenge(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Registers a user in the Quantdle backend with the default role
     * @summary Signs up an user
     * @param {SignUpRequest} body User and password for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public async signUp(body: SignUpRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<SignUpResponse>> {
        return UsersApiFp(this.configuration).signUp(body, options).then((request) => request(this.axios, this.basePath));
    }
}
