
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito'
import { useEffect, useMemo, useState } from 'react'
import { UsersApi, CandlesApi, InstrumentsApi, EAsApi } from '../api'
import { DownloadsApi } from '../download-api/api'
import { PaymentsApi } from '../stripe-api/api'
import { WebsocketApi } from '../websocket-api/api'
import { getEnvironment } from '../constants/api.constants'
import { fetchAuthSession } from 'aws-amplify/auth'


export const useTokens = () => {

    const [accessToken, setAccessToken] = useState<string>()
    const [idToken, setIdToken] = useState<string>()


    const renewToken = async () => {
        try {
            const { tokens } = await fetchAuthSession({ forceRefresh: true });
            setAccessToken(tokens.accessToken.toString())
            setIdToken(tokens.idToken.toString())
        } catch (err) {
            console.log(err);
        }
    }


    useEffect(() => {

        const getToken = async () => {
            try {
                const tokens = await cognitoUserPoolsTokenProvider.getTokens()
                setAccessToken(tokens.accessToken.toString())
                setIdToken(tokens.idToken.toString())
                // renew token every 15 mins
                setInterval(renewToken, 1000 * 60 * 15)
            } catch (e) {
                // this error is because the user is not logged in just ignore it
                console.log(e)
            }

        }

        getToken()

    }, [])

    return { accessToken, idToken }

}

export const useQuantdleApi = () => {

    const { accessToken, idToken } = useTokens()
    const basePaths = getEnvironment()

    const apiKey = useMemo(() => {
        if (!idToken) return undefined
        return `Bearer ${idToken}`
    }, [idToken])

    const usersApi = useMemo(() => {

        if (!apiKey) return undefined
        return new UsersApi({
            apiKey: apiKey,
            basePath: basePaths.egressBasePath,
        })
    }, [apiKey])

    const candlesApi = useMemo(() => {
        if (!apiKey) return undefined
        return new CandlesApi({
            apiKey: apiKey,
            basePath: basePaths.egressBasePath,
        })
    }, [apiKey])

    const instrumentsApi = useMemo(() => {
        if (!apiKey) return undefined
        return new InstrumentsApi({
            apiKey: apiKey,
            basePath: basePaths.egressBasePath,
        })
    }, [apiKey])

    const downloadsApi = useMemo(() => {
        if (!apiKey) return undefined
        return new DownloadsApi({
            apiKey: apiKey,
            basePath: basePaths.downloadsBasePath,
        })
    }, [apiKey])

    const paymentsApi = useMemo(() => {
        if (!apiKey) return undefined
        return new PaymentsApi({
            apiKey: apiKey,
            basePath: basePaths.paymentBasePath,
        })
    }, [apiKey])

    const eaApi = useMemo(() => {
        if (!apiKey) return undefined
        return new EAsApi({
            apiKey: apiKey,
            basePath: basePaths.egressBasePath,
        })
    }, [apiKey])

    const websocketApi = useMemo(() => {
        if (!accessToken) return undefined
        return new WebsocketApi({
            accessToken: accessToken,
            basePath: basePaths.websocketBasePath,
        })
    }, [accessToken])


    return {
        usersApi,
        candlesApi,
        instrumentsApi,
        downloadsApi,
        paymentsApi,
        websocketApi,
        eaApi
    }

}